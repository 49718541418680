<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row
          flex
          align="center"
          justify="space-between"
          class="px-3 mb-4"
        >
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Meer cijfers?</span>
            <div>
              <v-btn
                v-if="!this.user.organisationTypeId == 2"
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsSummary' }"
              >
                samenvatting
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                v-else
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsProductions' }"
              >
                producties
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsPerformances' }"
              >
                voorstellingen
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsPerformances10weeks' }"
              >
                voorstellingen laatste 10 weken
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1125"></breadcrumbs-with-help>
        </v-row>

        <div v-if="!tablePerformancesLoading">
          <!-- Table -->
          <v-data-table
            :headers="tablePerformancesHeaders"
            :items="tablePerformances"
            :mobile-breakpoint="0"
            :loading="tablePerformancesLoading"
            :page="tablePerformancesPaging.page"
            :items-per-page="tablePerformancesPaging.itemsPerPage"
            hide-default-footer
            @update:current-items="tablePerformancesPaging.pageCount = Math.ceil(tablePerformances.length / tablePerformancesPaging.itemsPerPage)"
            @update:items-per-page="tablePerformancesPaging.pageCount = Math.ceil(tablePerformances.length / tablePerformancesPaging.itemsPerPage)"
            class="table-action table-expand mt-2"
            item-key="key"
            single-expand
            show-expand
            fixed-header
            :height="tablePerformances.length > 10 ? '55vh' : ''"
          >
            <template v-slot:[`item.startDate`]="{ item }">
              {{ item.startDateDate_formatted }}
            </template>

            <template v-slot:[`item.capacity`]="{ item }">
              {{ formatThousandsWithDot(item.capacity) }}
            </template>

            <template v-slot:expanded-row="{ columns, item }">
              <td
                :colspan="columns.length"
                class="pa-0"
              >
                <v-data-table
                  :headers="tablePerformancesDetailsHeaders"
                  :items="item.details"
                  :mobile-breakpoint="0"
                  hide-default-footer
                  :items-per-page="-1"
                  class="table-action"
                >
                  <template v-slot:[`item.type`]="{ item }">
                    <strong>{{ item.type }}</strong>
                  </template>
                </v-data-table>
              </td>
            </template>


          </v-data-table>

          <!-- Table settings -->
          <table-settings :items="tablePerformances" :paging="tablePerformancesPaging"
            :export-columns="downloadExportColumns" :file-name="'dip-verkoopmutaties-voorstellingen-laatste-7-dagen'"
            :sheet-name="'voorstellingen laatste 7 dagen'" :show-excel-button="true">
          </table-settings>
        </div>
        <div v-else>
          <div class="text-center progress-circular">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <h4 class="text-primary mt-5">Een moment geduld aub, je gegevens worden berekend. Dit kan even duren.</h4>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '../../components/TableSettings';
import functions from '../../services/functions.service';

export default {
  name: 'SalesMutationsPerformances7days',
  components: {
    BreadcrumbsWithHelp,
    TableSettings
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'verkoopmutaties',
          disabled: false,
          href: '/verkoopmutaties',
          bold: false,
        },
        {
          text: 'voorstellingen laatste 7 dagen',
          disabled: true,
          href: '',
          bold: true,
        },
      ],
      inPast: false,
      tablePerformances: [],
      performances: [],
      tablePerformancesLoading: true,
      tablePerformancesHeaders: [
        { title: 'productienummer', key: 'productionNumber', width: '10%' },
        { title: 'voorstellingsnr', key: 'performanceNumber', width: '10%' },
        { title: 'speeldatum', key: 'startDate', width: '10%' },
        { title: 'productie', key: 'productionTitle', width: '15%' },
        { title: 'genre', key: 'genreName', width: '15%' },
        { title: 'capaciteit', key: 'capacity' },
        { title: '', key: 'data-table-expand', align: 'end' },
      ],
      tablePerformancesPaging: {
        page: 1,
        pageCount: 25,
        itemsPerPage: 25,
      },
      tablePerformancesDetailsHeaders: [
        { title: '', key: 'type' },
        { title: 'Dag 1', key: 'dag1' },
        { title: 'Dag 2', key: 'dag2' },
        { title: 'Dag 3', key: 'dag3' },
        { title: 'Dag 4', key: 'dag4' },
        { title: 'Dag 5', key: 'dag5' },
        { title: 'Dag 6', key: 'dag6' },
        { title: 'Dag 7', key: 'dag7' },
      ],
      exportPerformances: [],
      exportPerformancesColumns: [],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMutations');
    this.getPerformances();
  },
  methods: {
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageView(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    getPerformances: function () {
      this.tablePerformances = [];

      ApiService.get('/SalesMutations/GetPeformancesForLastSevenDays')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.tablePerformances = response.data.result;

          for (var i = 0; i < this.tablePerformances.length; i++) {
            this.tablePerformances[i].startDateDate_formatted = functions.getDateView(this.tablePerformances[i].startDate);
            this.tablePerformances[i].key = i;

            var performanceDetails = [
              {
                type: 'aantal',
                preSale: '',
              },
              {
                type: 'recette',
                preSale: '',
              },
              {
                type: 'verschil',
              },
            ];
            const performance = this.tablePerformances[i];
            const days = performance.days;

            days.forEach((day, j) => {
              const dayIndex = j + 1;
               performanceDetails[0][`dag${dayIndex}`] = this.formatThousandsWithDot(day.ticketCount);
               performanceDetails[1][`dag${dayIndex}`] = functions.getEuroSignView(day.revenueInVat);
               performanceDetails[2][`dag${dayIndex}`] = this.formatThousandsWithDot(day.mutation);
               this.tablePerformancesDetailsHeaders[dayIndex].title = functions.getDateView(day.date);
            });
            this.tablePerformances[i].details = performanceDetails;
          }
          this.tablePerformancesLoading = false;
          this.createExportData();
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    createExportData: function () {
      if (this.tablePerformances.length > 0) {
        this.exportPerformancesColumns = [];
        this.exportPerformances = [];

        // create export columns
        for (var i = 1; i < 32; i++) {
          this.exportPerformancesColumns.push({
            label: '',
            field: 'column' + i,
          });
        }

        // create export data
        let date = new Date();
        this.exportPerformances.push({
          column1: this.user.organisationName,
          column3:
            'Gegenereerd op: ' +
            date.getDate() +
            '-' +
            (date.getMonth() + 1) +
            '-' +
            date.getFullYear() +
            ' ' +
            date.getHours() +
            ':' +
            (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
        });
        this.exportPerformances.push({});

        this.exportPerformances.push({
          column1: 'productienummer',
          column2: 'voorstellingsnr',
          column3: 'datum',
          column4: 'productie',

          column5: 'impresariaat',
          column6: 'producent',

          column7: 'genre',
          column8: '# capaciteit',
        });

        var dayColumstotal = 7 * 3;
        var dayCount = 0;
        for (var y = 0; y < dayColumstotal; y++) {
          var columnNummber = y + 9;
          var columnTitle = '';

          var dayPosition = (y + 1) % 3;
          if (dayPosition == 1) {
            columnTitle = 'aantal';
          } else if (dayPosition == 2) {
            columnTitle = 'recette';
          } else if (dayPosition == 0) {
            columnTitle = 'verschil';
          }
          this.exportPerformances[1]['column' + columnNummber] = functions.getDateView(
            this.tablePerformances[0].days[dayCount].date
          );
          this.exportPerformances[2]['column' + columnNummber] = columnTitle;
          if (dayPosition == 0) {
            dayCount++;
          }
        }

        this.tablePerformances.forEach((item) => {
          this.exportPerformances.push({
            column1: item.productionNumber,
            column2: item.performanceNumber,
            column3: item.startDateDate_formatted,
            column4: item.productionTitle,

            column5: item.agencyName,
            column6: item.producerName,

            column7: item.genreName,

            column8: item.capacity,

            column9: item.days[0].ticketCount,
            column10:
              item.days[0].revenueInVat != null && item.days[0].revenueInVat != ''
                ? parseFloat(item.days[0].revenueInVat.toFixed(2))
                : '',
            column11: item.days[0].mutation,

            column12: item.days[1].ticketCount,
            column13:
              item.days[1].revenueInVat != null && item.days[1].revenueInVat != ''
                ? parseFloat(item.days[1].revenueInVat.toFixed(2))
                : '',
            column14: item.days[1].mutation,

            column15: item.days[2].ticketCount,
            column16:
              item.days[2].revenueInVat != null && item.days[2].revenueInVat != ''
                ? parseFloat(item.days[2].revenueInVat.toFixed(2))
                : '',
            column17: item.days[2].mutation,

            column18: item.days[3].ticketCount,
            column19:
              item.days[3].revenueInVat != null && item.days[3].revenueInVat != ''
                ? parseFloat(item.days[3].revenueInVat.toFixed(2))
                : '',
            column20: item.days[3].mutation,

            column21: item.days[4].ticketCount,
            column22:
              item.days[4].revenueInVat != null && item.days[4].revenueInVat != ''
                ? parseFloat(item.days[4].revenueInVat.toFixed(2))
                : '',
            column23: item.days[4].mutation,

            column24: item.days[5].ticketCount,
            column25:
              item.days[5].revenueInVat != null && item.days[5].revenueInVat != ''
                ? parseFloat(item.days[5].revenueInVat.toFixed(2))
                : '',
            column26: item.days[5].mutation,

            column27: item.days[6].ticketCount,
            column28:
              item.days[6].revenueInVat != null && item.days[6].revenueInVat != ''
                ? parseFloat(item.days[6].revenueInVat.toFixed(2))
                : '',
            column29: item.days[6].mutation,
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
  watch: {
    tablePerformancesFilter: {
      handler: 'filterProductions',
      deep: true,
    },
  },
};
</script>