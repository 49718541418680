<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row flex align="center" justify="space-between" class="pa-2">
          <v-col></v-col>
          <v-col class="d-flex align-center justify-end flex-grow-0">
            <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase ms-2">
              Per productie
            </v-btn>
            <v-btn color="primary" variant="outlined" rounded depressed class="font-weight-bold text-lowercase ms-2"
              :to="{ name: 'SalesMonitorPerformances' }">
              Per voorstelling
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="75"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <filter-period :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                selectedDefault="thisSeason" @changePeriod="filterPeriod = $event"></filter-period>
              <v-autocomplete v-if="user.organisationTypeId !== 1" v-model="tableSalesMonitorFilter.agencyId"
                :items="agencies" item-text="text" label="impresariaat selecteren" clearable hide-details
                density="compact" bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
              <v-autocomplete v-if="user.organisationTypeId !== 3" v-model="tableSalesMonitorFilter.producerId"
                :items="producers" label="producent selecteren" clearable hide-details density="compact" bg-color="#fff"
                class="ma-1 input-sm"></v-autocomplete>
              <v-autocomplete v-if="user.organisationTypeId !== 2" v-model="tableSalesMonitorFilter.theaterId"
                :items="theaters" label="selecteer een theater" clearable hide-details density="compact" bg-color="#fff"
                class="ma-1 input-sm"></v-autocomplete>
              <v-text-field v-model="tableSalesMonitorFilter.productionName" label="productie zoeken" hide-details
                density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>
              <v-autocomplete v-model="tableSalesMonitorFilter.genreId" :items="genres" label="genre selecteren"
                clearable hide-details density="compact" bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
              <v-autocomplete v-if="user.organisationTypeId == 2" v-model="tableSalesMonitorFilter.areaId"
                :items="areaNames" item-text="text" label="zaal selecteren" clearable hide-details density="compact"
                bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tableSalesMonitorFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <filter-period :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                      selectedDefault="thisSeason" @changePeriod="filterPeriod = $event"></filter-period>
                    <v-autocomplete v-if="user.organisationTypeId !== 1" v-model="tableSalesMonitorFilter.agencyId"
                      :items="agencies" item-text="text" label="impresariaat selecteren" clearable hide-details dense
                      bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
                    <v-autocomplete v-if="user.organisationTypeId !== 3" v-model="tableSalesMonitorFilter.producerId"
                      :items="producers" label="producent selecteren" clearable hide-details dense bg-color="#fff"
                      class="ma-1 input-sm"></v-autocomplete>
                    <v-autocomplete v-if="user.organisationTypeId !== 2" v-model="tableSalesMonitorFilter.theaterId"
                      :items="theaters" label="selecteer een theater" hide-details dense bg-color="#fff"
                      class="ma-1 input-sm"></v-autocomplete>
                    <v-text-field v-model="tableSalesMonitorFilter.productionName" label="productie zoeken" hide-details
                      dense bg-color="#fff" class="ma-1 input-sm"></v-text-field>
                    <v-autocomplete v-model="tableSalesMonitorFilter.genreId" :items="genres" label="genre selecteren"
                      clearable hide-details dense bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
                    <v-autocomplete v-if="user.organisationTypeId == 2" v-model="tableSalesMonitorFilter.areaId"
                      :items="areaNames" item-text="text" label="zaal selecteren" clearable hide-details dense
                      bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableSalesMonitorFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" variant="outlined" dark
                      @click="clearFilterProductions()">reset
                      filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tableSalesMonitorHeadersFiltered" :items="tableSalesMonitorFiltered"
          :mobile-breakpoint="0" :loading="tableSalesMonitorLoading" :page.sync="tableSalesMonitorPaging.page"
          :items-per-page="tableSalesMonitorPaging.itemsPerPage" :item-class="addClassToTableRow" hide-default-footer
          @update:current-items="tableSalesMonitorPaging.pageCount = Math.ceil(tableSalesMonitorFiltered.length / tableSalesMonitorPaging.itemsPerPage)"
          @update:items-per-page="tableSalesMonitorPaging.pageCount = Math.ceil(tableSalesMonitorFiltered.length / tableSalesMonitorPaging.itemsPerPage)"
          @click:row="(_, x) => goToDetail(x.item)" class="table-action row-is-clickable" fixed-header
          :height="tableSalesMonitorFiltered.length > 10 ? '55vh' : ''">
          <template v-slot:[`item.title`]="{ item }">
            <span>{{ item.title }}</span>
            <span class="tooltip">Einde tour</span>
          </template>

          <template v-slot:[`item.localOccupancyRate`]="{ item }">
            <td class="tooltip-container">
              <span class="tooltip">
                <table>
                  <tbody>
                    <tr>
                      <th scope="row">capaciteit</th>
                      <td>{{ item.totalCapacity }}</td>
                    </tr>
                    <tr>
                      <th scope="row">%bezet</th>
                      <td>
                        {{
                          item.localOccupancyRate === 0
                            ? item.localOccupancyRate + '%'
                            : getPercentageView(item.localOccupancyRate)
                        }}
                      </td>
                    </tr>
                    <tr v-if="user.organisationTypeId == 2">
                      <th scope="row">%bezet landelijk</th>
                      <td>
                        {{
                          item.nationalOccupancyRate === 0
                            ? item.nationalOccupancyRate + '%'
                            : getPercentageView(item.nationalOccupancyRate)
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <v-progress-linear class="custom_progress" bg-color="grey-darken-2" height="20" width="500" rounded="true"
                :model-value="item.localOccupancyRate" color="#0abfb8">
                <div class="target" :style="{ left: item.nationalOccupancyRate + '%' }"
                  v-if="item.nationalOccupancyRate > 0 && user.organisationTypeId == 2"></div>
              </v-progress-linear>
            </td>
          </template>

          <template v-slot:[`item.totalTickets`]="{ item }">
            <td class="tooltip-container textual" v-if="item.averageTotalTickets != null">
              <span class="tooltip">
                <table>
                  <tbody>
                    <tr>
                      <th scope="row">landelijk gemiddelde</th>
                      <td>
                        {{ formatThousandsWithDot(item.averageTotalTickets) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <span>{{ formatThousandsWithDot(item.totalTickets) }}</span>
            </td>
            <div v-else>
              <span>{{ formatThousandsWithDot(item.totalTickets) }}</span>
            </div>
          </template>

          <template v-slot:[`item.totalRevenueInVat`]="{ item }">
            <span>{{ getEuroSign(item.totalRevenueInVat) }}</span>
          </template>

          <template v-slot:[`item.reservedTickets`]="{ item }">
            <span>{{ formatThousandsWithDot(item.reservedTickets) }}</span>
          </template>

          <template v-slot:[`item.blockedTickets`]="{ item }">
            <span>{{ formatThousandsWithDot(item.blockedTickets) }}</span>
          </template>

          <template v-slot:[`item.localPerformances`]="{ item }">
            <span>{{ formatThousandsWithDot(item.localPerformances) }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn runded="circle" elevation="0" size="small" color="warning" class="ml-1" icon="mdi-eye"
              @click.stop="goToDetail(item)">
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`body.append`] v-if="!tableSalesMonitorLoading">
            <tr class="table-selection-total" v-if="tableSalesMonitorFilterActive">
              <th class="title">selectie</th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.totalTickets) }}
              </th>
              <th class="text">
                {{ getEuroSign(this.tableSalesMonitorSelected.totalRevenueInVat) }}
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.reservedTickets) }}
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.blockedTickets) }}
              </th>
              <th class=""></th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.localPerformances) }}
              </th>
              <th class="" v-for="header in tableSalesMonitorHeadersFiltered.slice(7)" :key="header.value"></th>
            </tr>

            <tr class="table-total">
              <th class="title">totaal</th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.totalTickets) }}
              </th>
              <th class="text">
                {{ getEuroSign(this.tableSalesMonitorTotal.totalRevenueInVat) }}
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.reservedTickets) }}
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.blockedTickets) }}
              </th>
              <th class=""></th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.performancesCount) }}
              </th>
              <th class="" v-for="header in tableSalesMonitorHeadersFiltered.slice(7)" :key="header.value"></th>
            </tr>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <table-settings :items="tableSalesMonitorFiltered" :paging="tableSalesMonitorPaging"
          :export-columns="downloadExportColumns" :file-name="'dip-verkoop-en-publiek-producties-export'"
          :sheet-name="'Verkoopmonitor producties'" :show-excel-button="true">
        </table-settings>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import BreadcrumbsWithHelp from '../../components/BreadcrumbsWithHelp.vue';
import TableSettings from '../../components/TableSettings';
import functions from '../../services/functions.service';
import FilterPeriod from '../../components/FilterPeriod';

export default {
  name: 'SalesMonitorProductions',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
    FilterPeriod,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'verkoopmonitor',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      filterPeriod: {
        from: '',
        to: '',
      },
      productions: [],
      tableSalesMonitorSelected: {
        localPerformances: 0,
        sold: 0,
        reserved: 0,
        blocked: 0,
        totalRevenueInVat: 0,
        myShare: 0,
      },
      tableSalesMonitorTotal: {
        performancesCount: 0,
        sold: 0,
        reserved: 0,
        blocked: 0,
        totalRevenueInVat: 0,
        myShare: 0,
      },
      tableSalesMonitorFiltered: [],
      selectAgencyId: '',
      theaters: [],
      agencies: [],
      producers: [],
      genres: [],
      areaNames: [],
      tableSalesMonitorLoading: true,
      tableSalesMonitorHeaders: [
        { title: 'naam', key: 'title' },
        { title: 'verkocht', key: 'totalTickets' },
        { title: 'recette', key: 'totalRevenueInVat' },
        { title: 'gereserv.', key: 'reservedTickets' },
        { title: 'geblok.', key: 'blockedTickets' },
        {
          title: '% bezet',
          key: 'localOccupancyRate',
          sortable: false,
          width: '125px',
        },
        { title: 'aantal vrst.', key: 'localPerformances' },
        { title: 'producent', key: 'producerName' },
        { title: 'co-productie', key: 'isCoProduction', showFor: [1, 3] },
        { title: 'impresariaat', value: 'agencyName', showFor: [2, 3] },
        { title: '', value: 'action', sortable: false, align: 'end' },
      ],
      tableSalesMonitorFilterActive: false,
      tableSalesMonitorFilter: {
        productionName: '',
        agencyId: null,
        theaterId: null,
        producerId: null,
        genreId: null,
        areaId: null,
      },
      tableSalesMonitorPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableSalesMonitorFilterDialog: false,
      downloadExportColumns: [
        { label: 'nummer', field: 'number' },
        { label: 'naam', field: 'title' },
        { label: 'aantal vrijkaarten', field: 'freeTickets' },
        { label: 'verkocht normaal', field: 'normalTickets' },
        {
          label: 'verkocht landelijk gemiddelde',
          field: 'averageTotalTickets',
        },
        { label: 'recette normaal', field: 'normalRevenueInVat' },
        { label: 'verkocht korting', field: 'discountTickets' },
        { label: 'recette korting', field: 'discountRevenueInVat' },
        { label: 'verkocht overig', field: 'otherTickets' },
        { label: 'recette overig', field: 'otherRevenueInVat' },
        { label: 'verkocht totaal', field: 'totalTickets' },
        { label: 'recette totaal', field: 'totalRevenueInVat' },
        { label: 'gereserveerd', field: 'reservedTickets' },
        { label: 'recette reserveringen', field: 'reservedRevenueInVat' },
        { label: 'geblokkeerd', field: 'blockedTickets' },
        { label: 'capaciteit', field: 'totalCapacity' },
        { label: '%bezet', field: 'localOccupancyRate' },
        { label: '%bezet landelijk', field: 'nationalOccupancyRate' },
        { label: 'voorstellingen hier', field: 'localPerformances' },
        { label: 'voorstellingen landelijk', field: 'nationalPerformances' },
        { label: 'producent', field: 'producerName' },
        { label: 'co-producent', field: 'coProducers' },
        { label: 'muzikale begeleiding', field: 'accompanyingProducers' },
        { label: 'impresariaat', field: 'agencyName' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMonitor');
    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableSalesMonitorFilter,
    });
    this.tableSalesMonitorFilter = this.getFilter(this.$options.name);
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),
    ...mapMutations('storage', ['getStoredSalesMonitorProductions', 'getStoredSalesMonitorProductionsFilterPeriod']),

    goToDetail(row) {
      this.$router.push({
        name: 'SalesMonitorProductionsDetail',
        params: { productionNumber: row.number },
      });
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    getProductions: function () {
      this.productions = [];
      this.tableSalesMonitorFiltered = [];
      this.tableSalesMonitorLoading = true;

      if (
        this.$store.state.storage.storedSalesMonitorProductions != undefined &&
        this.$store.state.storage.storedSalesMonitorProductions.length > 0 &&
        this.filterPeriod.from.toString() == this.$store.state.storage.storedFilterPeriod.dateFrom.toString() &&
        this.filterPeriod.to.toString() == this.$store.state.storage.storedFilterPeriod.dateTo.toString()
      ) {
        this.productionsDataHandler(this.$store.state.storage.storedSalesMonitorProductions, true);
        this.filterProductions();
        this.tableSalesMonitorLoading = false;
      } else {
        ApiService.get('/SalesMonitor/GetProductions', {
          startDate: functions.jsDateToStringDate(this.filterPeriod.from),
          endDate: functions.jsDateToStringDate(this.filterPeriod.to),
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            this.productions = response.data.result;

            this.productionsDataHandler(this.productions, false);

            this.filterProductions();
            this.getStoredSalesMonitorProductions(this.productions);
            this.getStoredSalesMonitorProductionsFilterPeriod(this.filterPeriod);

            this.tableSalesMonitorLoading = false;
          })
          .catch((error) => {
            //eslint-disable-next-line
            console.log(error);
            this.errored = true;
          });
      }
    },
    productionsDataHandler: function (response, cachedProductions) {
      const vm = this;
      this.productions = response;

      for (var i = 0; i < this.productions.length; i++) {
        if (!cachedProductions) {
          if (this.productions[i].isCoProduction == true) {
            this.productions[i].isCoProduction = 'Ja';
          } else {
            this.productions[i].isCoProduction = 'Nee';
          }
        }
      }

      this.producers = functions.uniqueSorted(this.productions.map(p => ({
        value: p.producerId,
        title: p.producerName
      })), p => p.title);

      this.theaters = functions.uniqueSorted(this.productions.flatMap(p => p.theaters).map(t => ({
        value: t.id,
        title: t.name
      })), t => t.title);

      this.agencies = functions.uniqueSorted(this.productions.filter(p => p.agencyId != 0 && p.agencyName != null).map(p => ({
        value: p.agencyId,
        title: p.agencyName
      })), p => p.title);

      this.genres = functions.uniqueSorted(this.productions.map(p => ({
        value: p.genreId,
        title: p.genreName
      })), p => p.title);

      this.areaNames = functions.uniqueSorted(this.productions.flatMap(p => p.areas).map(a => ({
        value: a.id,
        title: a.name
      })), a => a.title);

      // Set genre filter
      const genreFromQuery = this.$route.query.genre;
      if (genreFromQuery) {
        const firstMatchingGenreIdx = vm.genres.findIndex((genre) => genre.text === genreFromQuery);
        if (firstMatchingGenreIdx !== -1) {
          const genreId = vm.genres[firstMatchingGenreIdx].value;
          this.tableSalesMonitorFilter.genreId = genreId;

          // Filter
          this.updateFilter({
            viewName: this.$options.name,
            filterObject: this.tableSalesMonitorFilter,
          });
        }
      }

      this.tableSalesMonitorTotal.performancesCount = this.calculateArraySum(this.productions, 'localPerformances');
      this.tableSalesMonitorTotal.totalTickets = this.calculateArraySum(this.productions, 'totalTickets');
      this.tableSalesMonitorTotal.reservedTickets = this.calculateArraySum(this.productions, 'reservedTickets');
      this.tableSalesMonitorTotal.blockedTickets = this.calculateArraySum(this.productions, 'blockedTickets');
      this.tableSalesMonitorTotal.totalRevenueInVat = this.calculateArraySum(this.productions, 'totalRevenueInVat');

      this.tableSalesMonitorFiltered = this.productions;

      // Paging
      this.checkFilter({
        viewName: this.$options.name + '-paging',
        filterObject: this.tableSalesMonitorPaging,
      });
      this.tableSalesMonitorPaging = this.getFilter(this.$options.name + '-paging');
    },
    filterProductions: function () {
      const vm = this;

      if (
        this.tableSalesMonitorFilter.productionName == '' &&
        (this.tableSalesMonitorFilter.agencyId == '' || this.tableSalesMonitorFilter.agencyId == undefined) &&
        (this.tableSalesMonitorFilter.theaterId == '' || this.tableSalesMonitorFilter.theaterId == undefined) &&
        (this.tableSalesMonitorFilter.producerId == '' || this.tableSalesMonitorFilter.producerId == undefined) &&
        (this.tableSalesMonitorFilter.genreId == '' || this.tableSalesMonitorFilter.genreId == undefined) &&
        (this.tableSalesMonitorFilter.areaId == '' || this.tableSalesMonitorFilter.areaId == undefined)
      ) {
        this.tableSalesMonitorFilterActive = false;
      } else {
        this.tableSalesMonitorFilterActive = true;
      }

      this.tableSalesMonitorFiltered = this.productions.filter(function (production) {
        return (
          cleanWords(production.title).indexOf(cleanWords(vm.tableSalesMonitorFilter.productionName)) !== -1 &&
          (production.agencyId == vm.tableSalesMonitorFilter.agencyId ||
            vm.tableSalesMonitorFilter.agencyId == '' ||
            vm.tableSalesMonitorFilter.agencyId == undefined) &&
          (production.theaters.filter((e) => e.id === vm.tableSalesMonitorFilter.theaterId).length > 0 ||
            vm.tableSalesMonitorFilter.theaterId == '' ||
            vm.tableSalesMonitorFilter.theaterId == undefined) &&
          (production.producerId == vm.tableSalesMonitorFilter.producerId ||
            vm.tableSalesMonitorFilter.producerId == '' ||
            vm.tableSalesMonitorFilter.producerId == undefined) &&
          (production.genreId == vm.tableSalesMonitorFilter.genreId ||
            vm.tableSalesMonitorFilter.genreId == '' ||
            vm.tableSalesMonitorFilter.genreId == undefined) &&
          (production.areas.filter((p) => p.id === vm.tableSalesMonitorFilter.areaId).length > 0 ||
            vm.tableSalesMonitorFilter.areaId == '' ||
            vm.tableSalesMonitorFilter.areaId == undefined)
        );
      });

      this.tableSalesMonitorSelected.localPerformances = this.calculateArraySum(
        this.tableSalesMonitorFiltered,
        'localPerformances'
      );
      this.tableSalesMonitorSelected.totalTickets = this.calculateArraySum(
        this.tableSalesMonitorFiltered,
        'totalTickets'
      );
      this.tableSalesMonitorSelected.reservedTickets = this.calculateArraySum(
        this.tableSalesMonitorFiltered,
        'reservedTickets'
      );
      this.tableSalesMonitorSelected.blockedTickets = this.calculateArraySum(
        this.tableSalesMonitorFiltered,
        'blockedTickets'
      );
      this.tableSalesMonitorSelected.totalRevenueInVat = this.calculateArraySum(
        this.tableSalesMonitorFiltered,
        'totalRevenueInVat'
      );

      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterProductions: function () {
      this.tableSalesMonitorFilter.productionName = '';
      this.tableSalesMonitorFilter.agencyId = '';
      this.tableSalesMonitorFilter.theaterId = '';
      this.tableSalesMonitorFilter.producerId = '';
      this.tableSalesMonitorFilter.genreId = '';
      this.tableSalesMonitorFilter.areaId = '';
      this.tableSalesMonitorFilterActive = false;
      this.tableSalesMonitorFilterDialog = false;
    },
    addClassToTableRow(item) {
      if (item.hasEnded) {
        return 'has-ended';
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
    ...mapGetters('storage', ['getFilter']),

    tableSalesMonitorHeadersFiltered() {
      return this.tableSalesMonitorHeaders.filter(
        (h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId)
      );
    },
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableSalesMonitorFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableSalesMonitorPaging,
    });
  },
  watch: {
    tableSalesMonitorFilter: {
      handler: 'filterProductions',
      deep: true,
    },
    filterPeriod: function () {
      this.getProductions();
    },
  },
};
</script>

<style lang="scss">
.target {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  max-width: 2px;
  background-color: #021e72;
}

td.text-start {
  position: relative;
}

tr.has-ended>td:first-of-type::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: var(--v-secondary-base);
}

tr.has-ended td.text-start>span+span.tooltip {
  position: absolute;
  left: 0.5rem;
}

tr.has-ended td.text-start:hover>span+span.tooltip {
  display: block;
}
</style>