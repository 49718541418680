<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="10"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card title="gegevens theater">
            <v-divider></v-divider>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>naam:</small><br /><strong>{{ theater.name }}</strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>klant DIP:</small><br />
                    <strong><span v-if="theater.isMember">Ja</span><span v-else>Nee</span></strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>startdatum:</small><br /><strong>{{ theater.isMemberSince }}</strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>branchevereniging:</small><br /><strong>{{ theater.branchAssociation }}</strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>ticketing systeem:</small><br /><strong>{{ theater.ticketSystem }}</strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>kaartstanden bijwerken:</small><br /><strong>{{
                      theater.ticketLinkTypeString
                    }}</strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <template v-if="theater.ticketLinkType == 3">
                <v-row no-gutters>
                  <v-col cols="12" class="py-0">
                    <p class="text-primary mb-0">
                      <small>ticketmatic clientname:</small><br /><strong>{{ theater.ticketClientName }}</strong>
                    </p>
                  </v-col>
                </v-row>
                <hr class="accent-hr my-2" />
              </template>
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>voorwaarden DIP geaccepteerd:</small>
                    <br /><strong><span v-if="theater.agreedToTerms">Ja</span><span v-else>Nee</span></strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>toon publieksmonitor:</small> <br /><strong><span
                        v-if="theater.hasPublicMonitorAccess">Ja</span><span v-else>Nee</span></strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>toon benchmark:</small> <br /><strong><span v-if="theater.hasBenchmarkAccess">Ja</span><span
                        v-else>Nee</span></strong>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card title="adres">
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>straat en huisnummer:</small> <br />
                    <strong>{{ theater.street + ' ' + theater.number }}</strong>
                  </p>
                  <hr class="accent-hr my-2" />
                  <p class="text-primary mb-0">
                    <small>postcode:</small> <br />
                    <strong>{{ theater.zipCode }}</strong>
                  </p>
                  <hr class="accent-hr my-2" />
                  <p class="text-primary mb-0">
                    <small>plaats:</small> <br />
                    <strong>{{ theater.city }}</strong>
                  </p>
                  <hr class="accent-hr my-2" />
                  <p class="text-primary mb-0">
                    <small>provincie:</small> <br />
                    <strong>{{ theater.province }}</strong>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <div>
                <p class="grey--text text--lighten-1 mb-2">
                  <small><i>{{ this.theater.lastEditedBy }}</i></small>
                </p>
                <!-- Bewerken -->
                <v-btn-edit class="mr-5" :to="{ name: 'AdminTheatersEdit' }">Gegevens Bewerken</v-btn-edit>
                <!-- Verwijderen -->
                <v-btn-delete @click="deleteItemDialog = true">Verwijderen</v-btn-delete>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
            <h4 class="text-primary font-weight-regular">gebruikers</h4>
            <v-btn rounded small elevation="0" color="primary" class="text-lowercase"
              :to="{ name: 'AdminTheatersLinkAdd' }">gebruiker toevoegen</v-btn>
          </v-row>

          <!-- Table -->
          <v-data-table :headers="tableTheatersUsersHeaders" :items="tableTheatersUsersFiltered" :mobile-breakpoint="0"
            :loading="tableTheatersUsersLoading" :items-per-page="-1" hide-default-footer class="table-action">
            <template v-slot:[`item.action`]="{ item }">
              <v-btn rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline" color="info"
                class="ml-1" :to="{
                  name: 'AdminTheatersLinkEdit',
                  params: { linkId: item.linkId, theaterId: theaterId },
                }">
                <v-icon color="primary">mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn rounded="circle" elevation="0" size="small" icon="mdi-delete" color="error" class="ml-1"
                @click="(deleteItemLink = item), (deleteItemLinkDialog = true);">
                <v-icon color="#fff">mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12">
          <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
            <h4 class="text-primary font-weight-regular">zalen</h4>
            <v-btn rounded small elevation="0" color="primary" class="text-lowercase"
              :to="{ name: 'AdminTheatersAreaAdd' }">zaal toevoegen</v-btn>
          </v-row>

          <!-- Table -->
          <v-data-table :headers="tableTheatersAreasHeaders" :items="tableTheatersAreasFiltered" :mobile-breakpoint="0"
            :loading="tableTheatersAreasLoading" :items-per-page="-1" hide-default-footer class="table-action">
            <template v-slot:[`item.action`]="{ item }">
              <v-btn rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline" color="info"
                class="ml-1" :to="{
                  name: 'AdminTheatersAreaEdit',
                  params: { areaId: item.id, theaterId: theaterId },
                }">
                <v-icon color="primary">mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn rounded="circle" elevation="0" size="small" icon="mdi-delete" color="error" class="ml-1"
                @click="(deleteItemArea = item), (deleteItemAreaDialog = true);">
                <v-icon color="#fff">mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Delete dialog starts here -->
      <v-dialog v-model="deleteItemDialog" persistent width="500">
        <v-card class="pa-6" flat>
          <v-card-title class="headline">{{ this.theater.name }} verwijderen?</v-card-title>
          <v-card-text>Weet je zeker dat je {{ this.theater.name }} wil verwijderen? Het is daarna niet meer terug te
            vinden.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="deleteItemDialog = false">Nee</v-btn>
            <v-btn color="primary" text @click="deleteItemTheater()">Ja</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete dialog link starts here -->
      <v-dialog v-model="deleteItemLinkDialog" persistent width="500">
        <v-card class="pa-6" flat>
          <v-card-title class="headline">Gebruikersrol verwijderen?</v-card-title>
          <v-card-text>Weet je zeker dat je deze gebruikersrol wil verwijderen? Het is daarna niet meer terug te
            vinden.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="deleteItemLinkDialog = false">Nee</v-btn>
            <v-btn color="primary" text @click="deleteItemLinkUser()">Ja</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete dialog area starts here -->
      <v-dialog v-model="deleteItemAreaDialog" persistent width="500">
        <v-card class="pa-6" flat>
          <v-card-title class="headline">Zaal verwijderen?</v-card-title>
          <v-card-text>Weet je zeker dat je deze zaal wil verwijderen? Het is daarna niet meer terug te
            vinden.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="deleteItemAreaDialog = false">Nee</v-btn>
            <v-btn color="primary" text @click="deleteItemTheaterArea()">Ja</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ this.snackbarText }}
        <template v-slot:actions>
          <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
            Sluiten
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminTheatersDetail',
  props: ['theaterId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      theater: {
        name: '',
        isMember: false,
        isMemberSince: '',
        branchAssociation: '',
        ticketSystem: '',
        ticketLinkType: 0,
        ticketLinkTypeString: '',
        ticketClientName: '',
        agreedToTerms: false,
        hasPublicMonitorAccess: false,
        hasBenchmarkAccess: false,
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: '',
        lastEditedBy: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'theaters',
          disabled: false,
          href: '/admin/theaters',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemDialog: false,
      deleteItemLinkDialog: false,
      deleteItemAreaDialog: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',

      tableTheatersUsersFiltered: [],
      tableTheatersUsersLoading: true,
      tableTheatersUsersHeaders: [
        { title: 'naam', key: 'userFullName' },
        { title: 'rol', key: 'roleName' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],

      tableTheatersAreasFiltered: [],
      tableTheatersAreasLoading: true,
      tableTheatersAreasHeaders: [
        { title: 'naam', key: 'name' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
    };
  },
  mounted() {
    this.getTheater();
    this.getTheaterUsers();
    this.getTheaterAreas();
  },
  methods: {
    getTheater: function () {
      ApiService.get('/Theaters/Get', {
        theaterId: this.theaterId,
      })
        .then((response) => {
          if (response.data.success) {
            const theater = response.data.result;

            this.breadcrumbs[2].text = theater.name;
            this.breadcrumbs[2].href = '/admin/theaters/' + theater.id;
            this.theater.name = theater.name;
            this.theater.isMember = theater.isMember;
            this.theater.isMemberSince = functions.getDateView(theater.isMemberSince);
            this.theater.branchAssociation = theater.branchAssociation != null ? theater.branchAssociation.name : '';
            this.theater.ticketSystem = theater.ticketSystem != null ? theater.ticketSystem.name : '';
            this.theater.ticketLinkType = theater.ticketLinkType;
            this.theater.ticketLinkTypeString =
              theater.ticketLinkType == 1
                ? 'handmatig'
                : theater.ticketLinkType == 2
                  ? 'api'
                  : theater.ticketLinkType == 3
                    ? 'ticketmatic'
                    : '-';
            this.theater.ticketClientName = theater.ticketClientName;
            this.theater.agreedToTerms = theater.agreedToTerms;
            this.theater.hasPublicMonitorAccess = theater.hasPublicMonitorAccess;
            this.theater.hasBenchmarkAccess = theater.hasBenchmarkAccess;
            this.theater.street = theater.street;
            this.theater.number = theater.number;
            this.theater.zipCode = theater.zipCode;
            this.theater.city = theater.city;
            this.theater.province = theater.province.name;
            this.theater.lastEditedBy = theater.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getTheaterUsers: function () {
      ApiService.get('/Theaters/GetUsers', {
        theaterId: this.theaterId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableTheatersUsersFiltered = response.data.result;
            this.tableTheatersUsersLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getTheaterAreas: function () {
      ApiService.get('/TheaterAreas/GetAll', {
        TheaterId: this.theaterId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.areas = response.data.result;

          this.tableTheatersAreasFiltered = this.areas;
          this.tableTheatersAreasLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    deleteItemTheater: function () {
      ApiService.delete('/Theaters/Delete', {
        theaterId: this.theaterId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.theater.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.snackbarColor = 'secondary';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/admin/theaters' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    deleteItemLinkUser: function () {
      ApiService.delete('/UserRoles/Delete', {
        linkId: this.deleteItemLink.linkId,
        organisationTypeId: this.deleteItemLink.organisationTypeId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableTheatersUsersFiltered.map((item) => item.linkId).indexOf(this.deleteItemLink.linkId);
            this.tableTheatersUsersFiltered.splice(i, 1);

            this.snackbarText = 'Gebruiker is verwijderd';
            this.deleteItemLinkDialog = false;
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    deleteItemTheaterArea: function () {
      ApiService.delete('/TheaterAreas/Delete', {
        theaterAreaId: this.deleteItemArea.id,
        organisationTypeId: this.deleteItemArea.organisationTypeId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableTheatersAreasFiltered.map((item) => item.id).indexOf(this.deleteItemArea.id);
            this.tableTheatersAreasFiltered.splice(i, 1);

            this.snackbarText = 'Zaal is verwijderd';
            this.deleteItemAreaDialog = false;
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
  },
  computed: {},
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
