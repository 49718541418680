<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row align="center" class="px-3 mb-4">
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Een nieuw theater toevoegen?</span>
            <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase"
              :to="{ name: 'AdminTheatersAdd' }">
              Aanmaken nieuw theater
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="8"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field v-model="tableTheatersFilter.name" label="op naam zoeken" hide-details variant="outlined"
                density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>
              <v-text-field v-model="tableTheatersFilter.city" label="zoek op plaats" hide-details variant="outlined"
                density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>
              <v-autocomplete v-model="tableTheatersFilter.customer" :items="[
                { value: 'ja', title: 'ja' },
                { value: 'nee', title: 'nee' },
                { value: '', title: 'ja/nee' },
              ]" type="text" label="klant DIP" hide-details variant="outlined" density="compact" bg-color="#fff"
                class="ma-1 input-sm"></v-autocomplete>
              <v-autocomplete v-model="tableTheatersFilter.status" :items="[
                { value: 1, title: 'ja' },
                { value: 6, title: 'nee' },
                { value: '', title: 'ja/nee' },
              ]" type="text" label="actief" hide-details variant="outlined" density="compact" bg-color="#fff"
                class="ma-1 input-sm"></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tableTheatersFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field v-model="tableTheatersFilter.name" label="op naam zoeken" hide-details dense outlined
                      background-color="#fff" class="mb-3"></v-text-field>
                    <v-text-field v-model="tableTheatersFilter.city" label="zoek op plaats" hide-details dense outlined
                      background-color="#fff" class="mb-3"></v-text-field>
                    <v-autocomplete v-model="tableTheatersFilter.customer" :items="[
                      { value: 'ja', text: 'ja' },
                      { value: 'nee', text: 'nee' },
                      { value: '', text: 'ja/nee' },
                    ]" item-text="text" label="klant DIP" hide-details dense outlined background-color="#fff"
                      class="mb-3"></v-autocomplete>
                    <v-autocomplete v-model="tableTheatersFilter.status" :items="[
                      { value: 1, text: 'ja' },
                      { value: 6, text: 'nee' },
                      { value: '', text: 'ja/nee' },
                    ]" item-text="text" label="actief" hide-details dense outlined background-color="#fff"
                      class="mb-3"></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableTheatersFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" outlined dark
                      @click="clearFilterTheaters()">reset
                      filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tableTheatersHeaders" :items="tableTheatersFiltered" :mobile-breakpoint="0"
          :loading="tableTheatersLoading" :page="tableTheatersPaging.page"
          :items-per-page="tableTheatersPaging.itemsPerPage" hide-default-footer
          @update:current-items="tableTheatersPaging.pageCount = Math.ceil(tableTheatersFiltered.length / tableTheatersPaging.itemsPerPage)"
          @update:items-per-page="tableTheatersPaging.pageCount = Math.ceil(tableTheatersFiltered.length / tableTheatersPaging.itemsPerPage)"
          @click:row="(_, x) => goToDetail(x.item)" class="table-action row-is-clickable"
          :height="tableTheatersFiltered.length > 10 ? '55vh' : ''">
          <template v-slot:[`item.action`]="{ item }">
            <v-btn v-if="item.status == 1" rounded="circle" elevation="0" size="small" icon="mdi-eye" color="warning"
              class="ml-1" @click.stop="goToDetail(item)">
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
            <v-btn v-if="item.status == 1" rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline"
              color="info" class="ml-1" @click.stop="goToEdit(item)">
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn v-if="item.status == 1" rounded="circle" elevation="0" size="small" icon="mdi-delete" color="error"
              class="ml-1" @click.stop="(deleteItem = item), (deleteItemDialog = true);">
              <v-icon color="#fff">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <table-settings :items="tableTheatersFiltered" :paging="tableTheatersPaging"
          :export-columns="downloadExportColumns" :file-name="'dip-theaters-export'" :sheet-name="'Theaters'"
          :show-excel-button="true"></table-settings>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog v-model="deleteItemDialog" persistent width="500">
      <v-card class="pa-6" flat>
        <v-card-title class="headline">{{ this.deleteItem.name }} verwijderen?</v-card-title>
        <v-card-text>Weet je zeker dat je {{ this.deleteItem.name }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="(deleteItemDialog = false), (deleteItem = '');">Nee</v-btn>
          <v-btn color="primary" text @click="deleteItemTheater()">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ this.snackbarText }}
      <template v-slot:actions>
        <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
          Sluiten
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';
import functions from '../../../services/functions.service';

export default {
  name: 'AdminTheaters',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'theaters',
          disabled: false,
          href: '/admin/theaters',
          bold: true,
        },
      ],
      theaters: [],
      tableTheatersFiltered: [],
      tableTheatersLoading: true,
      tableTheatersHeaders: [
        { title: 'id', key: 'id' },
        { title: 'naam', key: 'name' },
        { title: 'plaats', key: 'city' },
        { title: 'provincie', key: 'province.name' },
        { title: 'klant DIP', key: 'isMember' },
        { title: 'branchevereniging', key: 'brancheAssociationName' },
        { title: 'ticketing systeem', key: 'ticketSystemName' },
        { title: 'actief', key: 'statusString' },
        { title: '', key: 'action', sortable: false, align: 'end', width: '170px' },
      ],
      tableTheatersFilter: {
        name: '',
        city: '',
        customer: '',
        status: 1,
      },
      tableTheatersPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableTheatersFilterDialog: false,
      downloadExportColumns: [
        { label: 'ID', field: 'id' },
        { label: 'PNL-ID', field: 'pnlId' },
        { label: 'naam', field: 'name' },
        { label: 'straat', field: 'street' },
        { label: 'huisnummer', field: 'number' },
        { label: 'postcode', field: 'zipCode' },
        { label: 'plaats', field: 'city' },
        { label: 'klant DIP', field: 'isMember' },
        {
          label: 'klant since',
          field: 'isMemberSince',
          dataFormat: functions.getDateView,
        },
        { label: 'actief', field: 'statusString' },
        { label: 'branchevereniging', field: 'brancheAssociationName' },
        { label: 'ticketing systeem', field: 'ticketSystemName' },
        { label: 'voorwaarden', field: 'agreedToTerms' },
        { label: 'provincie', field: 'provinceName' },
      ],
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'secondary',
    };
  },
  mounted() {
    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableTheatersFilter,
    });
    this.tableTheatersFilter = this.getFilter(this.$options.name);

    this.getTheaters();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'AdminTheatersDetail',
        params: { theaterId: row.id },
      });
    },
    goToEdit(row) {
      this.$router.push({
        name: 'AdminTheatersEdit',
        params: { theaterId: row.id },
      });
    },
    getTheaters: function () {
      ApiService.get('/Theaters/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.theaters = response.data.result.map(theater => ({
            ...theater,
            isMember: theater.isMember ? 'Ja' : 'Nee',
            statusString: theater.status == 1 ? 'Ja' : 'Nee',
            agreedToTerms: theater.agreedToTerms ? 'Ja' : 'Nee',
            provinceName: theater.province.name,
            brancheAssociationName: theater.branchAssociation ? theater.branchAssociation.name : null,
            ticketSystemName: theater.ticketSystem ? theater.ticketSystem.name : null,
          }));

          this.tableTheatersFiltered = this.theaters;

          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tableTheatersPaging,
          });
          this.tableTheatersPaging = this.getFilter(this.$options.name + '-paging');

          this.filterTheaters();

          this.tableTheatersLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterTheaters: function () {
      const vm = this;
      this.tableTheatersFiltered = this.theaters.filter(function (theater) {
        return (
          cleanWords(theater.name).indexOf(cleanWords(vm.tableTheatersFilter.name)) !== -1 &&
          cleanWords(theater.city).indexOf(cleanWords(vm.tableTheatersFilter.city)) !== -1 &&
          cleanWords(theater.isMember).indexOf(cleanWords(vm.tableTheatersFilter.customer)) !== -1 &&
          (theater.status == vm.tableTheatersFilter.status || vm.tableTheatersFilter.status == '')
        );
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterTheaters: function () {
      this.tableTheatersFilter.name = '';
      this.tableTheatersFilter.city = '';
      this.tableTheatersFilter.customer = '';
      this.tableTheatersFilter.status = 1;
      this.tableTheatersFilterDialog = false;
    },
    deleteItemTheater: function () {
      ApiService.delete('/Theaters/Delete', {
        theaterId: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableTheatersLoading = true;
            this.getTheaters();

            this.snackbarText = this.deleteItem.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('storage', ['getFilter']),
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableTheatersFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableTheatersPaging,
    });
  },
  watch: {
    tableTheatersFilter: {
      handler: 'filterTheaters',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
