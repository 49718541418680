<template>
  <div>
    <v-row flex justify="center" align="center" class="pb-2" no-gutters>
      <v-col>
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="3"></breadcrumbs-with-help>
      </v-col>
      <v-col class="pl-2 d-flex align-center justify-end" v-if="results">
        <v-btn class="font-weight-bold text-lowercase ma-1" appendIcon="mdi-arrow-left" color="primary" height="40"
          @click="results = null">Terug
          naar zoeken</v-btn>
      </v-col>
    </v-row>

    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row class="flex align-center pb-2" no-gutters>
            <v-col cols="12" class="py-0" v-if="!results">
              <!-- Form -->
              <v-form @submit.prevent v-model="formValid" class="d-flex flex-column" ref="form">
                <v-col cols="12">
                  <v-card title="zoekopties">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col cols="6" class="py-1">
                          <date-picker :passed-date="fields.dateFromString" rules="date" @formatedDate="fields.dateFromString = $event" label="datum van"></date-picker>
                        </v-col>

                        <v-col cols="6" class="py-1">
                          <date-picker :passed-date="fields.dateToString" label="datum tot" rules="date" @formatedDate="fields.dateToString = $event"></date-picker>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" class="py-1">
                          <v-text-field class="text-primary" label="naam theater"
                            v-model="fields.theaterName"></v-text-field>
                        </v-col>

                        <v-col cols="6" class="py-1">
                          <v-text-field class="text-primary" label="id theater" v-model="fields.theaterId"
                            :rules="formValidationRules.number"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" class="py-1">
                          <v-autocomplete id="naam_producent" label="naam producent" v-model="fields.producerId" :items="producers"
                            @update:modelValue="getProductions"></v-autocomplete>
                        </v-col>

                        <v-col cols="6" class="py-1">
                          <v-text-field class="text-primary" label="id producent" v-model="fields.producerId"
                            :rules="formValidationRules.number"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" class="py-1">
                          <v-text-field class="text-primary" label="naam impresariaat"
                            v-model="fields.agencyName"></v-text-field>
                        </v-col>

                        <v-col cols="6" class="py-1">
                          <v-text-field class="text-primary" label="id impresariaat" v-model="fields.agencyId"
                            :rules="formValidationRules.number"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" class="py-1">
                          <v-autocomplete id="titel_productie" v-if="productions.length > 0" label="titel productie"
                            v-model="fields.productionNumber" :items="productions"></v-autocomplete>

                          <v-text-field v-else class="text-primary" label="titel productie"
                            v-model="fields.productionTitle"></v-text-field>
                        </v-col>

                        <v-col cols="6" class="py-1">
                          <v-text-field class="text-primary" label="nummer productie"
                            v-model="fields.productionNumber"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <div>
                        <v-btn-find @click="find" :loading="formLoading">Zoeken</v-btn-find>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-form>
            </v-col>
          </v-row>

          <v-data-table v-if="results" 
            :headers="tableHeaders" 
            :items="tablePerformanceFiltered" 
            :mobile-breakpoint="0"
            :loading="false" 
            :page="tablePerformancesPaging.page" 
            :items-per-page="tablePerformancesPaging.itemsPerPage"
            hide-default-footer
            @update:current-items="tablePerformancesPaging.pageCount = Math.ceil(tablePerformanceFiltered.length / tablePerformancesPaging.itemsPerPage)"
            @update:items-per-page="tablePerformancesPaging.pageCount = Math.ceil(tablePerformanceFiltered.length / tablePerformancesPaging.itemsPerPage)"
            class="table-action" 
            fixed-header :height="results.length > 10 ? '55vh' : ''">

            <template v-slot:[`item.startDate`]="{ item }">
              <span>{{ getDateTimeView(item.startDate) }}</span>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <!-- Delete -->
              <v-btn rounded="circle" elevation="0" size="small" icon="mdi-delete" color="error" v-if="!item.contractId"
                class="ml-1" @click="(deleteItem = item), (deleteItemDialog = true);"><v-icon
                  color="#fff">mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <!-- Table settings -->
          <table-settings v-if="results" :items="tablePerformanceFiltered" :paging="tablePerformancesPaging"
            :export-columns="downloadExportColumns" :file-name="'dip-voorstellingen-export'"
            :sheet-name="'Voorstellingen'" :show-excel-button="true">
          </table-settings>

        </v-col>
      </v-row>

      <!-- Delete dialog starts here -->
      <v-dialog v-model="deleteItemDialog" persistent width="500">
        <v-card class="pa-6" flat>
          <v-card-title class="headline">{{ this.deleteItem.id }} verwijderen?</v-card-title>
          <v-card-text>Weet je zeker dat je {{ this.deleteItem.id }} wil verwijderen? Het is daarna niet meer terug te
            vinden.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="(deleteItemDialog = false), (deleteItem = '');">Nee</v-btn>
            <v-btn color="primary" text @click="performDelete()">Ja</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ this.snackbarText }}
        <template v-slot:actions>
          <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
            Sluiten
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';
import ApiService from '@/services/api.service';
import functions from '@/services/functions.service';
import DatePicker from '../../../components/DatePicker';

export default {
  name: 'AdminPerformances',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
    DatePicker,
  },
  data() {
    return {
      formError: '',
      formLoading: false,
      formValid: false,
      results: null,
      formValidationRules: {},
      fields: {
        dateFromString: '',
        dateToString: '',
        theaterName: '',
        theaterId: '',
        producerId: '',
        agencyName: '',
        agencyId: '',
        productionNumber: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'voorstellingen',
          disabled: false,
          href: '/admin/voorstellingen',
          bold: true,
        },
      ],
      tablePerformanceFiltered: [],
      tablePerformancesPaging: {
        page: 1,
        pageCount: 25,
        itemsPerPage: 25,
      },
      tableHeaders: [
        { title: 'id', key: 'id' },
        { title: 'nummer', key: 'pkiNumber' },
        { title: 'productie', key: 'productionTitle' },
        { title: 'datum/tijd', key: 'startDate' },
        { title: 'theater', key: 'theaterName' },
        { title: 'zaal', key: 'areaName' },
        { title: 'contract-id', key: 'contractId' },
        { title: 'productienummer', key: 'productionNumber' },
        { title: 'status', key: 'state' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      downloadExportColumns: [{ label: 'id', field: 'id' }],
      deleteItem: '',
      deleteItemDialog: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'secondary',
      producers: [],
      productions: [],
    };
  },
  mounted() {
    this.checkFilter({ viewName: this.$options.name, filterObject: {} });

    this.formValidationRules = functions.getFormValidationRules();
    this.getProducers();
  },
  computed: {
    ...mapGetters('storage', ['getFilter']),
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),
    async find() {
      this.formError = '';
      const { valid } = await this.$refs.form.validate()
      if (!valid) { return; }
      if (
        !this.fields.dateFromString &&
        !this.fields.producerId &&
        !this.fields.theaterId &&
        !this.fields.theaterName &&
        !this.fields.agencyName &&
        !this.fields.agencyId
      ) {
        this.formError = 'vul minstens één van de volgende velden in: startdatum, producent, impresariaat of theater';
        return;
      }
      this.formLoading = true;
      this.fields.dateFrom = functions.getDateDatabase(this.fields.dateFromString);
      this.fields.dateTo = functions.getDateDatabase(this.fields.dateToString);
      ApiService.get('/Performances/Find', this.fields).then((r) => {
        this.results = r.data.result.map(result => ({
          ...result,
          state: functions.changeState(result.state)
        }));

        if (this.results.length > 0) {
          this.tablePerformanceFiltered = this.results;
          this.downloadExportColumns = Object.keys(this.results[0]).map((k) => ({ label: k, field: k }));
        }

        // Paging
        this.checkFilter({
          viewName: this.$options.name + '-paging',
          filterObject: this.tablePerformancesPaging,
        });
        this.tablePerformancesPaging = this.getFilter(this.$options.name + '-paging');

        this.formLoading = false;
      }).catch((error) => {
        console.log(error);
        this.formLoading = false;
        this.errored = true;
      });
    },
    performDelete() {
      ApiService.delete('/Performances/Delete', {
        performanceId: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.results.map((item) => item.id).indexOf(this.deleteItem.id);
            this.results.splice(i, 1);

            this.snackbarText = this.deleteItem.id + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'secondary';
            this.snackbar = true;

            this.getContracts();
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getDateTimeView: function (date) {
      return functions.getDateTimeView(date);
    },
    getProducers: function () {
      ApiService.get('/Productions/GetProducers').then(
        (response) =>
        (this.producers = response.data.result.map((r) => ({
          value: r.id,
          title: r.name,
        })))
      );
    },
    getProductions: function () {
      ApiService.get('/Productions/GetPerProducer', {
        ProducerId: this.fields.producerId,
        SeasonId: 0,
      }).then((response) => {
        let result = response.data.result;
        let sortedProductionsArray = [];

        sortedProductionsArray = result.map((element) => ({
          value: element.number,
          title: element.title,
        }));

        sortedProductionsArray.sort(function (a, b) {
          var textA = a.text.toUpperCase();
          var textB = b.text.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        this.productions = sortedProductionsArray;
      });
    },
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: {},
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tablePerformancesPaging,
    });
  },
};
</script>
