<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row v-if="rights.edit" align="center" class="px-3 mb-4">
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Een nieuwe gebruiker aanmaken?</span>
            <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase"
              :to="{ name: 'LibraryUsersAdd' }">
              Aanmaken nieuwe gebruiker
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="42"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field v-model="tableUsersFilter.name" label="op naam zoeken" hide-details density="compact"
                bg-color="#fff" class="ma-1 input-sm"></v-text-field>
              <v-text-field v-model="tableUsersFilter.email" label="op e-mail zoeken" hide-details bg-color="#fff"
                density="compact" class="ma-1 input-sm"></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tableUsersFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field v-model="tableUsersFilter.name" label="op naam zoeken" hide-details dense
                      bg-color="#fff" class="mb-3"></v-text-field>
                    <v-text-field v-model="tableUsersFilter.email" label="op e-mail zoeken" hide-details dense
                      bg-color="#fff" class="mb-3"></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableUsersFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" variant="outlined" dark
                      @click="clearFilterUsers()">reset filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tableUsersHeaders" :items="tableUsersFiltered" :mobile-breakpoint="0"
          :loading="tableUsersLoading" :page.sync="tableUsersPaging.page"
          :items-per-page="tableUsersPaging.itemsPerPage" hide-default-footer
          @update:current-items="tableUsersPaging.pageCount = Math.ceil(tableUsersFiltered.length / tableUsersPaging.itemsPerPage)"
          @update:items-per-page="tableUsersPaging.pageCount = Math.ceil(tableUsersFiltered.length / tableUsersPaging.itemsPerPage)"
          @click:row="(_, x) => goToDetail(x.item)" class="table-action row-is-clickable" fixed-header
          :height="tableUsersFiltered.length > 10 ? '60vh' : ''">
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ props: activatorProps }">
                <v-icon v-if="item.twoFactorEnabled" color="primary" v-bind="activatorProps">
                  mdi-two-factor-authentication
                </v-icon>
              </template>
              <span>Twee-factor ingeschakeld</span>
            </v-tooltip>
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-eye" color="warning" class="ml-1"
              @click.stop="goToDetail(item)">
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>

            <v-btn v-if="rights.edit" rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline"
              color="info" class="ml-1" @click.stop="goToEdit(item)">
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>

            <v-btn v-if="rights.edit && user.userId != item.id" rounded="circle" elevation="0" size="small"
              icon="mdi-delete" color="error" class="ml-1"
              @click.stop="(deleteItem = item), (deleteItemDialog = true);">
              <v-icon color="#fff">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <table-settings :items="tableUsersFiltered" :paging="tableUsersPaging" :export-columns="downloadExportColumns"
          :file-name="'dip-gebruikers-export'" :sheet-name="'Gebruikers'" :show-excel-button="true"></table-settings>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog v-model="deleteItemDialog" persistent width="500">
      <v-card class="pa-6" flat>
        <v-card-title class="headline">{{ this.deleteItem.name }} verwijderen?</v-card-title>
        <v-card-text>Weet je zeker dat je het account voor {{ this.deleteItem.name }} wil verwijderen? Het is daarna
          niet
          meer
          terug te vinden.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="(deleteItemDialog = false), (deleteItem = '');">Nee</v-btn>
          <v-btn color="primary" text @click="deleteItemUser()">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ this.snackbarText }}
      <template v-slot:actions>
        <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
          Sluiten
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';

export default {
  name: 'LibraryUsers',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/beheer/gebruikers',
          bold: true,
        },
      ],
      users: [],
      tableUsersFiltered: [],
      tableUsersLoading: true,
      tableUsersHeaders: [
        { title: 'naam', key: 'name' },
        { title: 'e-mailadres', key: 'email' },
        { title: 'gebruikersrol', key: 'role' },
        { title: 'mails', key: 'mails' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableUsersFilter: {
        name: '',
        email: '',
      },
      tableUsersPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableUsersFilterDialog: false,
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'secondary',
      editUserDialog: false,
      downloadExportColumns: [
        { label: 'ID', field: 'id' },
        { label: 'naam', field: 'name' },
        { label: 'e-mail', field: 'email' },
        { label: 'rol', field: 'role' },
        { label: 'contracten', field: 'contractMails' },
        { label: 'subsidies', field: 'subventionMails' },
        { label: 'voorstellingen', field: 'performanceMails' },
        { label: 'producties', field: 'productionMails' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryUsers');

    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableUsersFilter,
    });
    this.tableUsersFilter = this.getFilter(this.$options.name);

    this.getUsers();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'LibraryUsersDetail',
        params: { userId: row.id },
      });
    },
    goToEdit(row) {
      this.$router.push({
        name: 'LibraryUsersEdit',
        params: { userId: row.id },
      });
    },
    getUsers: function () {
      ApiService.get('/Users/GetAllPerOrganisation')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.users = response.data.result;
          this.tableUsersFiltered = this.users;

          this.users.forEach(user => {
            user.contractMails = user.receiveContractMails ? 'ja' : 'nee';
            user.performanceMails = user.receivePerformanceMails ? 'ja' : 'nee';
            user.productionMails = user.receiveProductionMails ? 'ja' : 'nee';
            user.subventionMails = user.receiveSubventionMails ? 'ja' : 'nee';

            let emailSettings = [];

            if (user.receiveContractMails) {
              emailSettings.push('contracten');
            }

            if (user.receivePerformanceMails) {
              emailSettings.push('voorstellingen');
            }

            if (user.receiveProductionMails) {
              emailSettings.push('producties');
            }

            if (user.receiveSubventionMails) {
              emailSettings.push('subsidies');
            }

            user.mails = emailSettings.join(', ');
          });

          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tableUsersPaging,
          });
          this.tableUsersPaging = this.getFilter(this.$options.name + '-paging');

          this.filterUsers();

          this.tableUsersLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    filterUsers: function () {
      const vm = this;
      this.tableUsersFiltered = this.users.filter(function (user) {
        return (
          cleanWords(user.name).indexOf(cleanWords(vm.tableUsersFilter.name)) !== -1 &&
          cleanWords(user.email).indexOf(cleanWords(vm.tableUsersFilter.email)) !== -1
        );
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterUsers: function () {
      this.tableUsersFilter.name = '';
      this.tableUsersFilter.email = '';
      this.tableUsersFilterDialog = false;
    },
    deleteItemUser: function () {
      ApiService.delete('/Users/DeletePerOrganisation', {
        linkId: this.deleteItem.linkId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.users.map((item) => item.id).indexOf(this.deleteItem.id);
            this.users.splice(i, 1);
            this.filterUsers();

            this.snackbarText = this.deleteItem.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
    ...mapGetters('storage', ['getFilter']),
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableUsersFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableUsersPaging,
    });
  },
  watch: {
    tableUsersFilter: {
      handler: 'filterUsers',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
