/* eslint-disable no-unused-vars */
import {
  VImg,
  VIcon,
  VList,
  VListItem,
  VContainer,
  VBtn,
  VApp,
  VListGroup,
  VNavigationDrawer,
  VSpacer,
  VMenu,
  VAppBar,
  VMain,
  VCardTitle,
  VCardText,
  VCardActions,
  VDialog,
  VTextField,
  VAlert,
  VForm,
  VCol,
  VCard,
  VCardSubtitle,
  VRow,
  VLayout,
  VListItemTitle,
  VBreadcrumbs,
  VBreadcrumbsItem,
  VTextarea,
  VProgressLinear,
  VChip,
  VDataTable,
  VPagination,
  VAutocomplete,
  VTooltip,
  VSnackbar,
  VDatePicker,
  VCheckbox,
  VSelect,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelTitle,
  VExpansionPanelText,
  VRadio,
  VRadioGroup,
  VDivider,
  VFileInput,
  VProgressCircular,
} from 'vuetify/lib/components';

export default {
  VBtnSave: {
    class: ['text-lowercase', 'align-self-start'],
    color: 'secondary',
    size: 'large',
    rounded: true,
    elevation: 0,
    appendIcon: 'mdi-content-save',
  },
  VBtnEdit: {
    class: ['text-lowercase', 'align-self-start'],
    color: 'primary',
    size: 'large',
    rounded: true,
    variant: 'outlined',
    elevation: 0,
    appendIcon: 'mdi-square-edit-outline',
  },
  VBtnDelete: {
    class: ['text-lowercase', 'align-self-start'],
    color: 'error',
    size: 'large',
    rounded: true,
    elevation: 0,
    appendIcon: 'mdi-delete',
  },
  VBtnCancel: {
    class: ['text-lowercase', 'align-self-start'],
    color: 'error',
    size: 'large',
    rounded: true,
    variant: 'outlined',
    elevation: 0,
    appendIcon: 'mdi-close',
  },
  VBtnFind: {
    class: ['text-lowercase', 'align-self-start'],
    color: 'secondary',
    size: 'large',
    rounded: true,
    elevation: 0,
    appendIcon: 'mdi-magnify',
  },
  VBtn: {
    elevation: '0',
  },
  VListItemTitle: {
    class: ['text-primary'],
  },
  VImg,
  VIcon,
  VList,
  VListItem,
  VListGroup,
  VContainer,
  VApp,
  VNavigationDrawer,
  VSpacer,
  VMenu,
  VAppBar,
  VMain,
  VCard: {
    flat: true,
  },
  VCardTitle,
  VCardSubtitle,
  VCardText,
  VCardActions,
  VDialog,
  VTextField: {
    variant: 'outlined',
    density: 'compact',
  },
  VTextarea: {
    variant: 'outlined',
    density: 'compact',
  },
  VAutocomplete: {
    variant: 'outlined',
    density: 'compact',
  },
  VSelect: {
    variant: 'outlined',
    density: 'compact',
  },
  VAlert,
  VForm,
  VCol,
  VRow,
  VLayout,
  VBreadcrumbs,
  VBreadcrumbsItem,
  VProgressLinear,
  VChip,
  VDataTable,
  VPagination,
  VTooltip,
  VSnackbar,
  VDatePicker,
  VCheckbox,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelTitle,
  VExpansionPanelText,
  VRadio,
  VRadioGroup,
  VDivider,
  VProgressCircular,
  VFileInput: {
    variant: 'outlined',
  },
};
