<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row class="d-flex" justify="center" align="center" no-gutters>
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs :items="breadcrumbs" class="pa-0 pl-4">
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :to="{ path: item.href }" :exact="true" :disabled="item.disabled">
                  <span class="text-primary" :class="{ 'font-weight-bold': item.bold }">{{ item.text }}</span>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help bg-color="#fff" help-id="38"></help>
          </v-col>

          <v-col cols="12" class="py-0">
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- Notification Details -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col cols="12" md="6" sm="10">
                  <h1 class="text-primary mb-5">{{ this.notification.title }}</h1>
                  <h4 class="text-primary mb-2 font-weight-regular">gegevens notificatie</h4>

                  <v-card class="pa-6 mb-1" flat>
                    <v-row no-gutters>
                      <v-col cols="12" class="py-0">
                        <p class="text-primary mb-0">
                          <small>titel:</small><br />
                          <strong>{{ notification.title }}</strong>
                        </p>
                      </v-col>
                    </v-row>

                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col cols="12" class="py-0">
                        <p class="text-primary mb-0">
                          <small>datum:</small><br />
                          <strong>{{ notification.date }}</strong>
                        </p>
                      </v-col>
                    </v-row>

                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col cols="12" class="py-0">
                        <p class="text-primary mb-0">
                          <small>bestemd voor:</small><br />
                          <strong>{{ notification.organisationType }}</strong>
                        </p>
                      </v-col>
                    </v-row>

                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col cols="12" class="py-0">
                        <p class="text-primary mb-0">
                          <small>tekst:</small><br />
                          <strong>{{ notification.text }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                  <div>
                    <p class="grey--text text--lighten-1 mt-0">
                      <small><i>{{ this.notification.lastEditedBy }}</i></small>
                    </p>

                    <!-- Bewerken -->
                    <v-btn rounded large elevation="0" variant="outlined" color="primary"
                      class="text-lowercase mb-5 mr-5 align-self-start"
                      :to="{ name: 'AdminNotificationsEdit' }">Gegevens Bewerken
                      <v-icon small right>mdi-square-edit-outline</v-icon></v-btn>

                    <!-- Verwijderen -->
                    <v-btn rounded large elevation="0" @click="deleteItemDialog = true" color="error"
                      class="text-lowercase mb-5 align-self-start">Verwijderen
                      <v-icon small right>mdi-delete</v-icon></v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog v-model="deleteItemDialog" persistent width="500">
      <v-card class="pa-6" flat>
        <v-card-title class="headline">{{ this.notification.title }} verwijderen?</v-card-title>
        <v-card-text>Weet je zeker dat je {{ this.notification.title }} wil verwijderen? Het is daarna niet meer terug
          te
          vinden.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteItemDialog = false">Nee</v-btn>
          <v-btn color="primary" text @click="deleteItemNotification()">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ this.snackbarText }}
      <template v-slot:actions>
        <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
          Sluiten
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';

import Help from '../../../components/Help.vue';

export default {
  name: 'AdminNotificationsDetail',
  props: ['notificationId'],
  components: {
    Help,
  },
  data() {
    return {
      notification: {
        title: '',
        text: '',
        date: '',
        organisationType: '',
        lastEditedBy: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'notificaties',
          disabled: false,
          href: '/admin/notificaties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemDialog: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
    };
  },
  mounted() {
    this.getNotification();
  },
  methods: {
    getNotification: function () {
      ApiService.get('/Notifications/Get', {
        id: this.notificationId,
      })
        .then((response) => {
          if (response.data.success) {
            const notification = response.data.result;
            this.breadcrumbs[2].text = notification.title;
            this.notification.title = notification.title;
            this.notification.date = functions.getDateTimeView(notification.showDate);
            this.notification.text = notification.text;
            this.notification.organisationType = notification.organisationTypeName;
            this.notification.lastEditedBy = notification.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    deleteItemNotification: function () {
      ApiService.delete('/Notifications/Delete', {
        notificationId: this.notificationId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.notification.title + ' is verwijderd';
            this.deleteItemDialog = false;
            this.snackbarColor = 'secondary';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/admin/notificaties' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {},
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
