<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1143"></breadcrumbs-with-help>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <!-- Filter -->
            <ProductionDetailFilters :user="user" :value="filterWidgets" :filter-options="filterOptions"
              @input="handleFilterWidgetsChange" />
          </v-col>
        </v-row>

        <v-row align="center" class="px-3 mb-4 print-hide" :class="!hasTooFewPurchases ? '' : 'hide'">
          <!-- Overlapanalyse & compare with  -->
          <v-col class="d-flex justify-end align-center flex-wrap flex-md-nowrap">
            <filter-period :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
              selectedDefault="thisSeason" @changePeriod="filterPeriod = $event"></filter-period>
            <v-autocomplete v-model="filterWidgets.compareWith" v-if="this.user.organisationTypeId == 2"
              :items="compareWithOptions" label="vergelijken met" clearable hide-details variant="outlined"
              density="compact" bg-color="#fff" class="mr-2 ml-2 input-sm flex-grow-0"></v-autocomplete>
            <v-btn color="secondary" v-if="this.user.organisationTypeId == 2" rounded depressed
              class="font-weight-bold text-lowercase mt-2 mt-md-0 mr-2" :to="{
                name: 'PublicMonitorProductionsOverlapAnalysis',
                params: { productionId: this.productionNumber },
              }">
              overlapanalyse
            </v-btn>
          </v-col>
        </v-row>

        <div v-show="!this.tableProductionPerformancesLoading">
          <template v-if="hasTooFewPurchases">
            <!-- hasTooFewPurchases  -->
            <v-row flex>
              <v-col cols="12">
                <div class="too-few-purchases-fallback">
                  <span>De huidige selectie bevat minder dan 5 kaartkopers</span>
                </div>
              </v-col>
            </v-row>
            <v-row flex>
              <v-col cols="12" md="6">
                <v-card class="py-5 px-3 pb-0 mb-18" flat height="100%">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6" class="py-0" v-if="this.production.productionNumber != null">
                        <p class="text-primary font-weight-medium mb-0">productienummer</p>
                        <h3 class="text-primary border-bottom pb-0 mb-4">
                          {{ this.production.productionNumber }}
                        </h3>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <p class="text-primary font-weight-medium mb-0">titel</p>
                        <h3 class="text-primary border-bottom pb-0 mb-4">
                          {{ this.production.title }}
                        </h3>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <p class="text-primary font-weight-medium mb-0">genre</p>
                        <h3 class="text-primary border-bottom pb-0 mb-4">
                          {{ this.production.genre }}
                        </h3>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <p class="text-primary font-weight-medium mb-0" v-if="!this.production.playDay">
                          eerste speeldatum
                        </p>
                        <p class="text-primary font-weight-medium mb-0" v-else>
                          speeldatum
                        </p>
                        <h3 class="text-primary border-bottom pb-0 mb-4">
                          {{ getDateTimeView(this.production.firstStartDate) }}
                        </h3>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <div v-if="!this.production.playDay">
                          <p class="text-primary font-weight-medium mb-0">laatste speeldatum</p>
                          <h3 class="text-primary border-bottom pb-0 mb-4">
                            {{ getDateTimeView(this.production.lastStartDate) }}
                          </h3>
                        </div>
                        <div v-else>
                          <p class="text-primary font-weight-medium mb-0">speeldag</p>
                          <h3 class="text-primary border-bottom pb-0 mb-4">
                            {{ this.production.playDay }}
                          </h3>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </div>

        <div v-show="!this.tableProductionPerformancesLoading">
          <v-row flex v-if="!hasTooFewPurchases">
            <!-- Basis informatie -->
            <v-col cols="12" sm="12" md="6" class="hw-print">
              <v-card class="py-5 px-3 pb-0 mb-18" flat height="100%">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12" class="py-0" v-if="this.production.productionNumber != null">
                      <p class="text-primary font-weight-medium mb-0">productienummer</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ this.production.productionNumber }}
                      </h3>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <p class="text-primary font-weight-medium mb-0">titel</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ this.production.title }}
                      </h3>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 print-hide">
                      <p class="text-primary font-weight-medium mb-0">genre</p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ this.production.genre }}
                      </h3>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <p class="text-primary font-weight-medium mb-0" v-if="!this.production.playDay">
                        eerste speeldatum
                      </p>
                      <p class="text-primary font-weight-medium mb-0" v-else>
                        speeldatum
                      </p>
                      <h3 class="text-primary border-bottom pb-0 mb-4">
                        {{ getDateTimeView(this.production.firstStartDate) }}
                      </h3>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <div v-if="!this.production.playDay">
                        <p class="text-primary font-weight-medium mb-0">laatste speeldatum</p>
                        <h3 class="text-primary border-bottom pb-0 mb-4">
                          {{ getDateTimeView(this.production.lastStartDate) }}
                        </h3>
                      </div>
                      <div v-else>
                        <p class="text-primary font-weight-medium mb-0">speeldag</p>
                        <h3 class="text-primary border-bottom pb-0 mb-4">
                          {{ this.production.playDay }}
                        </h3>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>

            <!-- Bezettingsgraad -->
            <v-col cols="12" sm="12" md="6" class="pa-0 ma-0 d-flex flex-grow-1 align-stretch hw-print" v-if="
              this.publicMonitorOccupancyRate.capacity != 0 ||
              this.publicMonitorOccupancyRate.totalTickets != 0 ||
              this.publicMonitorOccupancyRate.purchases != 0
            ">
              <TopInfoCards :publicMonitorOccupancyRate="publicMonitorOccupancyRate" />
            </v-col>

            <!-- Leeftijd per klant -->
            <v-col cols="12" sm="12" md="4" class="pa-0 ma-0 d-block d-sm-flex d-md-block hw-print">
              <age-visitors :cols-desktop="12" :cols-tablet="12" :cols-mobile="12" :help-id="1133"
                :widget-data="publicMonitorCustomerAges"></age-visitors>
            </v-col>

            <!-- Moment van aankoop -->
            <v-col cols="12" sm="12" md="4" class="pa-0 ma-0 d-block d-sm-flex d-md-block hw-print">
              <purchase-periods :cols-desktop="12" :cols-tablet="12" :cols-mobile="12" :help-id="1134"
                :widget-data="publicMonitorPurchasePeriods"></purchase-periods>
            </v-col>

            <!-- Prijssoorten -->
            <v-col cols="12" sm="12" md="4" class="pa-0 ma-0 d-block d-sm-flex d-md-block hw-print">
              <sales-types :cols-desktop="12" :cols-tablet="12" :cols-mobile="12" :help-id="1135"
                :widget-data="publicMonitorTicketSorts"></sales-types>
            </v-col>

            <!-- Loyaliteit -->
            <v-col cols="12" sm="12" md="4" class="pa-0 ma-0 d-flex hw-print">
              <loyalties :cols-desktop="12" :cols-tablet="12" :cols-mobile="12" :help-id="1136"
                :widget-data="publicMonitorLoyalties"></loyalties>
            </v-col>

            <v-col sm="12" class="page-break"></v-col>

            <!-- Kaarten per klant -->
            <v-col cols="12" sm="12" md="4" class="pa-0 ma-0 d-block d-sm-flex d-md-block hw-print">
              <tickets-per-customer :cols-desktop="12" :cols-tablet="12" :cols-mobile="12" :help-id="1137"
                :widget-data="publicMonitorTicketsPerCustomer"></tickets-per-customer>
            </v-col>

            <!-- Gedrag kaartkopers -->
            <v-col cols="12" sm="12" md="4" class="pa-0 ma-0 d-block d-sm-flex d-md-block hw-print">
              <buyer-behaviors :cols-desktop="12" :cols-tablet="12" :cols-mobile="12" :help-id="1138"
                :widget-data="publicMonitorBuyerBehaviors"></buyer-behaviors>
            </v-col>

            <!-- Geslacht kaartkopers -->
            <v-col cols="12" sm="12" md="4" class="pa-0 ma-0 d-flex hw-print">
              <buyer-genders :cols-desktop="12" :cols-tablet="12" :cols-mobile="12" :help-id="1139"
                :widget-data="publicMonitorBuyerGenders"></buyer-genders>
            </v-col>

            <!-- Geografische spreiding  -->
            <v-col cols="12" sm="12" md="4" class="pa-0 ma-0 d-flex hw-print">
              <buyer-distances :cols-desktop="12" :cols-tablet="12" :cols-mobile="12" :help-id="1140"
                :widget-data="publicMonitorBuyerDistances"></buyer-distances>
            </v-col>

            <!-- Dag van aankoop  -->
            <v-col cols="12" sm="12" md="4" class="pa-0 ma-0 d-flex hw-print">
              <day-of-purchase :cols-desktop="12" :cols-tablet="12" :cols-mobile="12" :help-id="1140"
                :widget-data="publicMonitorDayOfPurchases"></day-of-purchase>
            </v-col>

            <!-- Verkoop per gemeente -->
            <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 d-flex print-big-chart">
              <map-chart-municipalities :cols-desktop="12" :cols-tablet="12" :cols-mobile="12" :help-id="1141"
                :mapData="mapDataMunicipalities"></map-chart-municipalities>
            </v-col>

            <!-- Kaartverkoop over tijd  -->
            <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 d-flex print-big-chart">
              <ticket-sales-over-time :cols-desktop="12" :cols-tablet="12" :cols-mobile="12" :help-id="1140"
                :widget-data="publicMonitorTicketSalesOverTime"></ticket-sales-over-time>
            </v-col>

            <v-col sm="12" class="page-break" style="height: 120mm"></v-col>
          </v-row>

          <div v-if="!hasTooFewPurchases" class="pt-5">
            <p class="text-primary font-weight-medium mb-0">voorstellingen</p>
          </div>

          <!-- Table -->
          <DetailProductionPerformancesTable v-if="!hasTooFewPurchases" :user="user"
            :production-number="Number(productionNumber)"
            :table-production-performances-filtered="tableProductionPerformancesFiltered"
            :loading="tableProductionPerformancesLoading" />

          <v-row class="my-1 print-hide">
            <v-col class="d-flex align-center justify-end" cols="12" sm="12" md="12">
              <!-- Download PDF -->
              <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase mr-2" @click="printPage">
                Download PDF
                <v-icon class="ml-1" right>mdi-file-move</v-icon>
              </v-btn>

              <!-- Download Excel -->
              <v-btn color="warning" rounded depressed class="font-weight-bold text-lowercase text-primary">
                <vue-excel-xlsx class="v-btn font-weight-bold text-lowercase" :data="exportData"
                  :columns="exportDataColumns" :file-name="'dip-publieksmonitor-producties'"
                  :sheet-name="'publieksmonitor producties'">download XLS <v-icon
                    right>mdi-file-move</v-icon></vue-excel-xlsx>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-show="this.tableProductionPerformancesLoading">
          <div class="text-center progress-circular">
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
            <h4 class="text-primary mt-5">Een moment geduld aub, je gegevens worden berekend. Dit kan even duren.</h4>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { TokenService } from '@/services/storage.service';
import ApiService from '@/services/api.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import functions from '../../services/functions.service';
import FilterPeriod from '../../components/FilterPeriod';
import ProductionDetailFilters from './components/ProductionDetail/SearchFilters.vue';
import TopInfoCards from './components/ProductionDetail/TopInfoCards.vue';
import DetailProductionPerformancesTable from './components/ProductionDetail/ProductionPerformancesTable.vue';
import createExportData from './components/ExportData/createExportData';

// Widgets
import AgeVisitors from '../../components/widgets/PublicMonitor/AgeVisitors.vue';
import PurchasePeriods from '../../components/widgets/PublicMonitor/PurchasePeriod.vue';
import Loyalties from '../../components/widgets/PublicMonitor/Loyalties.vue';
import TicketsPerCustomer from '../../components/widgets/PublicMonitor/TicketsPerCustomer.vue';
import SalesTypes from '../../components/widgets/PublicMonitor/SalesTypes.vue';
import BuyerBehaviors from '../../components/widgets/PublicMonitor/BuyerBehaviors.vue';
import MapChartMunicipalities from '../../components/widgets/PublicMonitor/MapChartMunicipalities.vue';
import BuyerGenders from '../../components/widgets/PublicMonitor/BuyerGenders.vue';
import BuyerDistances from '../../components/widgets/PublicMonitor/BuyerDistances.vue';
import DayOfPurchase from '../../components/widgets/PublicMonitor/DayOfPurchase.vue';
import TicketSalesOverTime from '../../components/widgets/PublicMonitor/TicketSaleOverTime.vue';

export default {
  name: 'PublicMonitorProductionsDetail',
  props: ['productionNumber'],
  components: {
    BreadcrumbsWithHelp,
    FilterPeriod,
    AgeVisitors,
    PurchasePeriods,
    Loyalties,
    TicketsPerCustomer,
    SalesTypes,
    BuyerBehaviors,
    MapChartMunicipalities,
    BuyerGenders,
    BuyerDistances,
    DayOfPurchase,
    TicketSalesOverTime,
    ProductionDetailFilters,
    TopInfoCards,
    DetailProductionPerformancesTable,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'publieksmonitor',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/publieksmonitor/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      production: {
        id: 0,
        title: '',
        productionNumber: '',
        genre: '',
        firstStartDate: '',
        lastStartDate: '',
        playDay: '',
      },
      filterPeriod: {
        from: '',
        to: '',
      },
      tableProductionPerformancesFiltered: [],
      tableProductionPerformancesLoading: true,
      tablePublicMonitorPage: 1,
      tablePublicMonitorPageCount: 0,
      tablePublicMonitorItemsPerPage: 25,
      tablePublicMonitorFilterDialog: false,
      publicMonitorCustomerAges: [],
      publicMonitorPurchasePeriods: [],
      publicMonitorLoyalties: [],
      publicMonitorTicketsPerCustomer: [],
      publicMonitorTicketSorts: [],
      publicMonitorOccupancyRate: {},
      publicMonitorBuyerBehaviors: [],
      publicMonitorBuyerGenders: [],
      publicMonitorBuyerDistances: [],
      publicMonitorDayOfPurchases: [],
      publicMonitorTicketSalesOverTime: [],
      filterWidgets: {},
      filterOptions: {
        salesTypes: [],
        originalSalesTypes: [],
        buyerAges: [],
        buyerLoyalties: [],
        buyerBehaviors: [],
        purchasePeriods: [],
        buyerGenders: [],
        buyerDistances: [],
        dayOfPurchases: [],
        ticketCounts: [],
      },
      ticketSalesOverTimes: [],
      compareWithOptions: [],
      mapDataMunicipalities: null,
      hasTooFewPurchases: false,
      exportData: [],
      exportDataColumns: [],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('PublicMonitor');
    this.createExportData(this, 'production');
  },
  beforeUnmount() {
    window.onbeforeprint = null;
  },
  destroyed() {
    const tooltips = document.body.querySelectorAll('.tooltip');
    if (tooltips && tooltips.length > 0) {
      tooltips.forEach((el) => {
        document.body.removeChild(el);
      });
    }
  },
  methods: {
    ...mapActions('storage', ['toggleDrawerState']),

    createExportData,

    printPage() {
      this.$store.commit('storage/toggleDrawerState', false);
      setTimeout(() => {
        window.onbeforeprint = this.beforePrinting();
        window.print();
        window.onafterprint = this.afterPrinting();
      }, 500);
    },
    beforePrinting() {
      document.getElementsByTagName('header')[0].style.display = 'none';
      for (const x of document.querySelectorAll('.hw-print.col-sm-12')) {
        x.className = x.className.replace('col-sm-12', 'col-sm-6');
        x.style.height = 'calc(148.5mm - 22px)';

        var canvas = x.querySelectorAll('canvas')[0];
        if (canvas != null) {
          canvas.style.width = '100%';
          canvas.style.height = '100%';
        }
      }
    },
    afterPrinting() {
      document.getElementsByTagName('header')[0].style.display = 'block';
      for (const x of document.querySelectorAll('.hw-print.col-sm-6')) {
        x.className = x.className.replace('col-sm-6', 'col-sm-12');
        x.style.height = 'auto';
        var canvas = x.querySelectorAll('canvas')[0];
        if (canvas != null) {
          canvas.style.width = '155px';
          canvas.style.height = '155px';
        }
      }
    },
    getDateTimeView(date) {
      return functions.getDateTimeView(date);
    },
    getProductionPerformances: function () {
      const vmLegacy = { $set: (o, k, v) => o[k] = v };
      this.performances = [];
      this.tableProductionPerformancesFiltered = [];
      this.tableProductionPerformancesLoading = true;

      ApiService.get('/PublicMonitor/GetProduction', {
        startDate: functions.jsDateToStringDate(this.filterPeriod.from),
        endDate: functions.jsDateToStringDate(this.filterPeriod.to),
        ProductionId: this.productionNumber,
        SalesType: this.filterWidgets.salesType,
        OriginalSalesType: this.filterWidgets.originalSalesType,
        BuyerAge: this.filterWidgets.buyerAge,
        BuyerLoyalty: this.filterWidgets.buyerLoyalty,
        BuyerBehavior: this.filterWidgets.buyerBehavior,
        PurchasePeriod: this.filterWidgets.purchasePeriod,
        BuyerGender: this.filterWidgets.buyerGender,
        BuyerDistance: this.filterWidgets.buyerDistance,
        Ticket: this.filterWidgets.ticketCount,
        CompareWith: this.filterWidgets.compareWith,
        DayOfPurchase: this.filterWidgets.dayOfPurchase,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          const production = response.data.result;

          this.production.id = production.id;
          this.production.title = production.title;
          this.production.productionNumber = production.number;
          this.production.genre = production.genre;
          this.production.firstStartDate = production.firstStartDate;
          this.production.lastStartDate = production.lastStartDate;
          this.production.playDay = production.playDay;
          this.breadcrumbs[2].text = production.title;
          this.hasTooFewPurchases = production.hasTooFewPurchases;

          this.performances = response.data.result.performances;

          for (var i = 0; i < this.performances.length; i++) {
            vmLegacy.$set(this.performances[i], 'startDateView', functions.getDateTimeView(this.performances[i].startDate));
          }

          this.mapDataMunicipalities = response.data.result.municipalities;
          return response;
        })
        .then((response) => {
          this.tableProductionPerformancesFiltered = this.performances;

          this.publicMonitorCustomerAges = response.data.result.buyerAges;
          this.publicMonitorPurchasePeriods = response.data.result.purchasePeriods;

          this.publicMonitorLoyalties = response.data.result.buyerLoyalties;
          this.publicMonitorTicketsPerCustomer = response.data.result.tickets;

          // if theater show raw ticket data
          if (this.user.organisationTypeId == 2) {
            this.publicMonitorTicketSorts = response.data.result.originalSalesTypes;
          } else {
            this.publicMonitorTicketSorts = response.data.result.salesTypes;
          }

          this.publicMonitorOccupancyRate = response.data.result.occupancy;
          this.publicMonitorBuyerBehaviors = response.data.result.buyerBehaviors;
          this.publicMonitorBuyerGenders = response.data.result.buyerGenders;
          this.publicMonitorBuyerDistances = response.data.result.buyerDistances;
          this.publicMonitorDayOfPurchases = response.data.result.dayOfPurchases;
          this.publicMonitorTicketSalesOverTime = response.data.result.salesOverTimes;

          // Filter options
          this.filterOptions.salesTypes =
            this.user.organisationTypeId == 2
              ? response.data.result.originalSalesTypeSelect
              : response.data.result.salesTypeSelect;
          this.filterOptions.buyerAges = response.data.result.buyerAgeSelect;
          this.filterOptions.buyerLoyalties = response.data.result.buyerLoyaltySelect;
          this.filterOptions.originalSalesTypes = response.data.result.originalSalesTypeSelect;
          this.filterOptions.buyerBehaviors = response.data.result.buyerBehaviorSelect;
          this.filterOptions.purchasePeriods = response.data.result.purchasePeriodSelect;
          this.filterOptions.buyerGenders = response.data.result.buyerGenderSelect;
          this.filterOptions.buyerDistances = response.data.result.buyerDistanceSelect;
          this.filterOptions.ticketCounts = response.data.result.ticketSelect;
          this.filterOptions.dayOfPurchases = response.data.result.dayOfPurchaseSelect;
          this.compareWithOptions = response.data.result.compareWithSelect;

          this.tableProductionPerformancesLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    handleFilterWidgetsChange(filterWidgets) {
      this.filterWidgets = filterWidgets;
      this.getProductionPerformances();
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
  watch: {
    filterPeriod: function () {
      this.getProductionPerformances();
    },
  },
};
</script>

<style lang="scss">
.pa-0 {
  break-inside: avoid-page;
}

.page-break {
  display: none;
  height: 50px;
}

@media print {
  @page {
    size: auto;
    /* auto is the initial value */
    margin: 0mm;
    /* this affects the margin in the printer settings */
  }

  .v-main {
    padding: 0 !important;
  }

  .v-navigation-drawer {
    transform: translateX(-100%) !important;
  }

  .v-application--wrap {
    display: block;
  }

  .v-sheet {
    display: block;
    page-break-inside: avoid;
  }

  .print-hide {
    display: none;
  }

  @-moz-document url-prefix() {
    .page-break {
      display: block;
    }
  }

  .col-10,
  .col-11 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 0;
    justify-content: center;
    display: flex;
  }

  header {
    display: none !important;
  }

  .row.no-gutters>.col {
    display: none;
  }

  .row .legend {
    display: none;
  }

  html .header,
  html header {
    display: none;
  }

  .legend h3 {
    display: none;
  }

  .legend {
    display: none !important;
    position: absolute;
    left: 0;
    top: 50px;
  }

  .v-data-table__wrapper {
    height: inherit !important;
    overflow-x: hidden;
  }

  main {
    padding: 0 !important;
  }

  .col-10>div,
  .col-11>div {
    width: 260px;
  }

  .map-buttons {
    display: none;
  }

  header {
    display: none !important;
  }

  .row.no-gutters>.col {
    display: none;
  }

  .row.px-3.mb-4.align-center {
    position: absolute;
    top: -20px;
    right: 0;
  }

  button.ml-2.v-btn.v-btn--fab.v-btn--has-bg.v-btn--round.theme--light.v-size--x-small {
    display: none;
  }

  .print-big-chart {
    height: 297mm / 2;
    box-sizing: border-box;
    overflow: hidden;

    .col-3 {
      display: none;
    }

    canvas {
      display: block;
      max-width: 200mm;
      max-height: 150mm;
      transform-origin: left top;
    }
  }

  .v-data-table__wrapper {
    height: inherit;
    overflow-x: hidden;
  }

  .map-buttons {
    display: none;
  }

  main.v-main {
    padding: 0 !important;
  }
}
</style>
