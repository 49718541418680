<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row flex align="center" justify="space-between" class="px-3 mb-4">
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Meer cijfers?</span>
            <div>
              <v-btn v-if="this.user.organisationTypeId != 2" color="primary" rounded depressed
                class="font-weight-bold text-lowercase ma-1" :to="{ name: 'SalesMutationsSummary' }">
                samenvatting
                <v-icon small right>mdi-chart-bar-stacked</v-icon>
              </v-btn>
              <v-btn v-else color="primary" rounded depressed class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsProductions' }">
                producties
                <v-icon small right>mdi-chart-bar-stacked</v-icon>
              </v-btn>

              <v-btn v-if="this.user.organisationTypeId != 2" color="primary" rounded depressed
                class="font-weight-bold text-lowercase ma-1" :to="{ name: 'SalesMutationsProductions10weeks' }">
                producties laatste 10 weken
                <v-icon small right>mdi-chart-bar-stacked</v-icon>
              </v-btn>
              <v-btn v-else color="primary" rounded depressed class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsPerformances10weeks' }">
                voorstellingen laatste 10 weken
                <v-icon small right>mdi-chart-bar-stacked</v-icon>
              </v-btn>

              <v-btn v-if="this.user.organisationTypeId != 2" color="primary" rounded depressed
                class="font-weight-bold text-lowercase ma-1" :to="{ name: 'SalesMutationsProductions7days' }">
                producties laatste 7 dagen
                <v-icon small right>mdi-chart-bar-stacked</v-icon>
              </v-btn>
              <v-btn v-else color="primary" rounded depressed class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsPerformances7days' }">
                voorstellingen laatste 7 dagen
                <v-icon small right>mdi-chart-bar-stacked</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1124"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-lg-flex">
              <filter-date-from-to :defaultFrom="this.filter.filterDateOne" :defaultTo="this.filter.filterDateTwo"
                @changePeriod="filter.filterPeriod = $event" :opens-left="true"></filter-date-from-to>
              <v-text-field v-model="tablePerformancesFilter.productionTitle" label="productie zoeken" hide-details
                variant="outlined" density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>
            </div>
            <!-- Filter dialog -->
            <div class="d-lg-none">
              <v-dialog v-model="tablePerformancesFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <filter-date-from-to :defaultFrom="this.filter.filterDateOne" :defaultTo="this.filter.filterDateTwo"
                      @changePeriod="filter.filterPeriod = $event"></filter-date-from-to>
                    <v-text-field v-model="tablePerformancesFilter.productionTitle" label="productie zoeken"
                      hide-details variant="outlined" density="compact" bg-color="#fff"
                      class="ma-1 input-sm"></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <!-- <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed @click="tablePerformancesFilterDialog = false">filter overzicht <v-icon small right>mdi-filter</v-icon></v-btn> -->
                    <v-btn class="font-weight-bold text-lowercase" color="error" variant="outlined" dark
                      @click="clearFilterDateFromTo()">sluit filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <div v-if="!tablePerformancesLoading">
          <!-- Table -->
          <v-data-table 
            :headers="tablePerformancesHeadersFiltered" 
            :items="tablePerformancesFiltered"
            :v-model:sort-by="sortBy" 
            :mobile-breakpoint="0" 
            :loading="tablePerformancesLoading"
            :page="tablePerformancesPaging.page" 
            :items-per-page="tablePerformancesPaging.itemsPerPage"
            hide-default-footer
            @update:current-items="tablePerformancesPaging.pageCount = Math.ceil(tablePerformancesFiltered.length / tablePerformancesPaging.itemsPerPage)"
            @update:items-per-page="tablePerformancesPaging.pageCount = Math.ceil(tablePerformancesFiltered.length / tablePerformancesPaging.itemsPerPage)"
            @click:row="(_, x) => goToDetail(x.item)" class="row-is-clickable" fixed-header
            :height="tablePerformancesFiltered.length > 10 ? '55vh' : ''">
            <template v-slot:[`item.startDate`]="{ item }">
              <span>{{ item.startDateDate_formatted }}</span>
            </template>

            <template v-slot:[`item.capacity`]="{ item }">
              <span>{{ formatThousandsWithDot(item.capacity) }}</span>
            </template>

            <template v-slot:[`item.weekOne`]="{ item }">
              <span>{{ formatThousandsWithDot(item.weekOne) }}</span>
            </template>

            <template v-slot:[`item.weekTwo`]="{ item }">
              <span>{{ formatThousandsWithDot(item.weekTwo) }}</span>
            </template>

            <template v-slot:[`item.mutationNumber`]="{ item }">
              <v-chip v-if="item.mutationNumber > 0" color="success" small label><strong>{{ item.mutationNumber
                  }}</strong></v-chip>
              <v-chip v-else-if="item.mutationNumber < 0" color="error" small label><strong>{{ item.mutationNumber
                  }}</strong></v-chip>
              <template v-else-if="item.mutationNumber === 0">
                {{ item.mutationNumber }}
              </template>
            </template>

            <template v-slot:[`item.mutationRate`]="{ item }">
              <span>{{ getPercentageView(item.mutationRate) }}</span>
            </template>

            <template v-slot:[`item.occupancyRate`]="{ item }">
              <span>{{ getPercentageView(item.occupancyRate) }}</span>
            </template>

            <template v-slot:[`item.ticketPrice`]="{ item }">
              <span>{{ getEuroSign(item.ticketPrice) }}</span>
            </template>

            <template v-slot:[`item.revenueInVat`]="{ item }">
              <span>{{ getEuroSign(item.revenueInVat) }}</span>
            </template>

            <template v-slot:[`body.append`] v-if="!tablePerformancesLoading">
              <tr class="table-total">
                <th class="title">totaal</th>
                <th class=""></th>
                <th class=""></th>
                <th class=""></th>
                <th class=""></th>
                <th class=""></th>
                <th v-if="this.user.organisationTypeId != 2" class=""></th>
                <th v-if="this.user.organisationTypeId != 2" class=""></th>
                <th class="text">{{ this.tablePerformances.capacity }}</th>
                <th class="text">{{ this.tablePerformances.weekOne }}</th>
                <th class="text">{{ this.tablePerformances.weekTwo }}</th>
                <th class="text">
                  {{ this.tablePerformances.mutationNumber }}
                </th>
                <th class="text">
                  {{ getPercentageView(this.tablePerformances.mutationRate) }}
                </th>
                <th class="text">
                  {{ getPercentageView(this.tablePerformances.occupancyRate) }}
                </th>
                <th class="text">
                  {{ getEuroSign(this.tablePerformances.ticketPrice) }}
                </th>
                <th class="text">
                  {{ getEuroSign(this.tablePerformances.revenueInVat) }}
                </th>
              </tr>
            </template>
          </v-data-table>

          <!-- Table settings -->
          <table-settings :items="tablePerformancesFiltered" :paging="tablePerformancesPaging"
            :export-columns="exportPerformancesColumns" :file-name="'dip-verkoopmutaties-voorstellingen'"
            :sheet-name="'voorstellingen'" :show-excel-button="true"></table-settings>
        </div>
        <div v-else>
          <div class="text-center progress-circular">
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
            <h4 class="text-primary mt-5">Een moment geduld aub, je gegevens worden berekend. Dit kan even duren.</h4>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import BreadcrumbsWithHelp from '../../components/BreadcrumbsWithHelp.vue';
import functions from '../../services/functions.service';
import FilterDateFromTo from '../../components/FilterDateFromTo';
import TableSettings from '@/components/TableSettings.vue';

export default {
  name: 'SalesMutationsPerformances',
  components: {
    BreadcrumbsWithHelp,
    FilterDateFromTo,
    TableSettings
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'verkoopmutaties',
          disabled: true,
          href: '',
          bold: false,
        },
        {
          text: 'voorstellingen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      filter: {
        filterDateOne: '',
        filterDateTwo: '',
        filterPeriod: {
          from: '',
          to: '',
        },
      },
      timeStamp: '',
      tablePerformancesFilter: {
        productionTitle: '',
      },
      productions: [],
      tablePerformances: [],
      tablePerformancesFiltered: [],
      tablePerformancesTotal: {
        performanceAmount: 0,
        capacity: 0,
        theatersAmount: 0,
        weekOne: 0,
        weekTwo: 0,
        mutation: 0,
        percentageMutation: 0,
        percentageBooked: 0,
        ticketPrice: 0,
        ticketPriceExport: 0,
        recette: 0,
        recetteExport: 0,
      },
      performances: [],
      tablePerformancesLoading: true,
      sortBy: [{ key: 'mutationNumber', order: 'desc' }],
      // sortBy: 'mutationNumber',
      // sortDesc: true,
      tablePerformancesHeaders: [
        { title: 'productienummer', key: 'productionNumber' },
        { title: 'voorstellingsnr', key: 'performanceNumber' },
        { title: 'datum', key: 'startDate' },
        { title: 'tijd', key: 'startTime' },
        { title: 'theater', key: 'theaterName', showFor: [1, 3] },
        { title: 'plaats', key: 'theaterCity', showFor: [1, 3] },
        { title: 'productie', key: 'productionTitle' },
        { title: 'genre', key: 'genreName' },
        { title: 'capaciteit', key: 'capacity' },
        { title: 'wk', key: 'weekOne' },
        { title: 'wk', key: 'weekTwo' },
        { title: '# mutatie', key: 'mutationNumber' },
        { title: '% mutatie', key: 'mutationRate' },
        { title: '% bezet', key: 'occupancyRate' },
        { title: 'gemiddelde kaartprijs', key: 'ticketPrice' },
        { title: 'recette', key: 'revenueInVat' },
      ],
      tablePerformancesFilterActive: false,
      tablePerformancesPaging: {
        page: 1,
        pageCount: 25,
        itemsPerPage: 25,
      },
      tablePerformancesFilterDialog: false,
      exportPerformances: [],
      exportPerformancesColumns: [],
    };
  },
  beforeMount() {
    this.filterDefaultDate();
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMutations');
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'SalesMonitorPerformancesDetail',
        params: {
          productionNumber: row.productionNumber,
          performanceId: row.performanceId,
        },
      });
    },
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageView(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    filterDefaultDate: function () {
      this.checkFilter({
        viewName: 'SalesMutationsOveral',
        filterObject: this.filter,
      });
      this.filter = {};
      this.filter = this.getFilter('SalesMutationsOveral');

      if (this.filter.filterDateOne == '') {
        var now = new Date();
        var previousWeek = new Date();
        previousWeek = new Date(previousWeek.setDate(previousWeek.getDate() - 7));
        this.filter.filterDateOne = functions.getDateView(functions.jsDateToStringDate(previousWeek));
        this.filter.filterDateTwo = functions.getDateView(functions.jsDateToStringDate(now));
      } else {
        this.filter.filterDateOne = functions.getDateView(
          functions.jsDateToStringDate(new Date(this.filter.filterPeriod.from))
        );
        this.filter.filterDateTwo = functions.getDateView(
          functions.jsDateToStringDate(new Date(this.filter.filterPeriod.to))
        );
      }
    },
    clearFilterDateFromTo: function () {
      this.tablePerformancesFilterDialog = false;
    },
    getPerformances: function () {
      const vmLegacy = { $set: (o, k, v) => o[k] = v };
      this.tablePerformances = [];

      ApiService.get('/SalesMutations/GetPeformancesForDateRange', {
        firstDate: this.filter.filterPeriod.from,
        lastDate: this.filter.filterPeriod.to,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.tablePerformancesTotal = response.data.result;
          this.tablePerformances = response.data.result.performances;

          for (var i = 0; i < this.tablePerformances.length; i++) {
            vmLegacy.$set(
              this.tablePerformances[i],
              'startDateDate_formatted',
              functions.getDateView(this.tablePerformances[i].startDate)
            );
            vmLegacy.$set(this.tablePerformances[i], 'startTime', functions.getTimeView(this.tablePerformances[i].startDate));
            vmLegacy.$set(
              this.tablePerformances[i],
              'occupancyRate',
              this.tablePerformances[i].occupancyRate != null
                ? parseFloat(this.tablePerformances[i].occupancyRate.toFixed(2))
                : ''
            );
            vmLegacy.$set(
              this.tablePerformances[i],
              'mutationRate',
              this.tablePerformances[i].mutationRate != null
                ? parseFloat(this.tablePerformances[i].mutationRate.toFixed(2))
                : ''
            );
            vmLegacy.$set(
              this.tablePerformances[i],
              'ticketPrice',
              this.tablePerformances[i].ticketPrice != null
                ? parseFloat(this.tablePerformances[i].ticketPrice.toFixed(2))
                : ''
            );
            vmLegacy.$set(
              this.tablePerformances[i],
              'revenueInVat',
              this.tablePerformances[i].revenueInVat != null
                ? parseFloat(this.tablePerformances[i].revenueInVat.toFixed(2))
                : ''
            );

            for (var j = 0; j < this.tablePerformances[i].days.length; j++) {
              vmLegacy.$set(this.tablePerformances[i], 'weekOne', this.tablePerformances[i].days[0].ticketCount);
              vmLegacy.$set(this.tablePerformances[i], 'weekTwo', this.tablePerformances[i].days[1].ticketCount);
              const wkIndex = this.tablePerformancesHeadersFiltered.findIndex((j) => j.title === 'capaciteit') + 1;
              this.tablePerformancesHeadersFiltered[j + wkIndex].title = functions.getDateView(
                this.tablePerformances[i].days[j].date
              );
            }
          }

          this.tablePerformances.capacity = this.tablePerformancesTotal.capacity;
          if (this.tablePerformancesTotal.days.length > 0) {
            this.tablePerformances.weekOne = this.tablePerformancesTotal.days[0].ticketCount;
            this.tablePerformances.weekTwo = this.tablePerformancesTotal.days[1].ticketCount;
          }
          this.tablePerformances.mutationNumber = this.tablePerformancesTotal.mutationNumber;
          this.tablePerformances.mutationRate =
            this.tablePerformancesTotal.mutationRate != null ? this.tablePerformancesTotal.mutationRate : '';
          this.tablePerformances.occupancyRate =
            this.tablePerformancesTotal.occupancyRate != null ? this.tablePerformancesTotal.occupancyRate : '';
          this.tablePerformances.ticketPrice =
            this.tablePerformancesTotal.ticketPrice != null ? this.tablePerformancesTotal.ticketPrice : '';
          this.tablePerformances.revenueInVat = this.tablePerformancesTotal.revenueInVat;

          this.tablePerformancesFiltered = this.tablePerformances;

          this.filterPerformances();

          this.tablePerformancesLoading = false;
          this.createExportData();
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterPerformances: function () {
      const vm = this;

      if (this.tablePerformancesFilter.productionTitle == '') {
        this.tablePerformancesFilterActive = false;
      } else {
        this.tablePerformancesFilterActive = true;
      }

      this.tablePerformancesFiltered = this.tablePerformances.filter(function (production) {
        return (
          cleanWords(production.productionTitle).indexOf(cleanWords(vm.tablePerformancesFilter.productionTitle)) !== -1
        );
      });

      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearfilterProductions: function () {
      this.tablePerformancesFilter.productionTitle = '';
    },
    createExportData: function () {
      if (this.tablePerformances.length > 0) {
        this.exportPerformancesColumns = [];
        this.exportPerformances = [];

        // create export columns
        for (var i = 1; i < 32; i++) {
          this.exportPerformancesColumns.push({
            label: '',
            field: 'column' + i,
          });
        }

        // create export data
        let date = new Date();
        this.exportPerformances.push({
          column1: this.user.organisationName,
          column3:
            'Gegenereerd op: ' +
            date.getDate() +
            '-' +
            (date.getMonth() + 1) +
            '-' +
            date.getFullYear() +
            ' ' +
            date.getHours() +
            ':' +
            (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
        });
        this.exportPerformances.push({});

        this.exportPerformances.push({
          column1: 'productienummer',
          column2: 'voorstellingsnr',
          column3: 'datum',
          column4: 'productie',

          column5: 'theater',
          column6: 'impresariaat',
          column7: 'producent',

          column8: 'genre',

          column9: '# capaciteit',
          column10: functions.getDateView(this.tablePerformances[0].days[0].date),
          column11: functions.getDateView(this.tablePerformances[0].days[1].date),
          column12: '# mutatie',
          column13: '% mutatie',
          column14: '% bezet',
          column15: 'gemiddelde kaartprijs',
          column16: 'recette',
        });

        this.tablePerformances.forEach((item) => {
          this.exportPerformances.push({
            column1: item.productionNumber,
            column2: item.performanceNumber,
            column3: functions.getDateTimeView(item.startDate),
            column4: item.productionTitle,
            column5: item.theaterName,
            column6: item.agencyName,
            column7: item.producerName,

            column8: item.genreName,

            column9: item.capacity,
            column10: item.days[0].ticketCount,
            column11: item.days[1].ticketCount,
            column12: item.mutationNumber,
            column13:
              item.mutationRate != null && item.mutationRate != '' ? parseFloat(item.mutationRate.toFixed(2)) : '',
            column14:
              item.occupancyRate != null && item.occupancyRate != '' ? parseFloat(item.occupancyRate.toFixed(2)) : '',
            column15: item.ticketPrice != null && item.ticketPrice != '' ? parseFloat(item.ticketPrice.toFixed(2)) : '',
            column16:
              item.revenueInVat != null && item.revenueInVat != '' ? parseFloat(item.revenueInVat.toFixed(2)) : '',
          });
        });

        this.exportPerformances.push({
          column8: 'Totaal',
          column9: this.tablePerformances.capacity,
          column10: this.tablePerformances.weekOne,
          column11: this.tablePerformances.weekTwo,
          column12: this.tablePerformances.mutationNumber,
          column13:
            this.tablePerformances.mutationRate != null && this.tablePerformances.mutationRate != ''
              ? parseFloat(this.tablePerformances.mutationRate.toFixed(2))
              : '',
          column14:
            this.tablePerformances.occupancyRate != null && this.tablePerformances.occupancyRate != ''
              ? parseFloat(this.tablePerformances.occupancyRate.toFixed(2))
              : '',
          column15:
            this.tablePerformances.ticketPrice != null && this.tablePerformances.ticketPrice != ''
              ? parseFloat(this.tablePerformances.ticketPrice.toFixed(2))
              : '',
          column16:
            this.tablePerformances.revenueInVat != null && this.tablePerformances.revenueInVat != ''
              ? parseFloat(this.tablePerformances.revenueInVat.toFixed(2))
              : '',
        });
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),

    ...mapGetters('storage', ['getFilter']),

    tablePerformancesHeadersFiltered() {
      return this.tablePerformancesHeaders.filter(
        (h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId)
      );
    },
  },
  beforeUnmount() {
    this.updateFilter({
      viewName: 'SalesMutationsOveral',
      filterObject: this.filter,
    });
  },
  watch: {
    tablePerformancesFilter: {
      handler: 'filterPerformances',
      deep: true,
    },
    filter: {
      handler: 'getPerformances',
      deep: true,
    },
  },
};
</script>