<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row class="d-flex" justify="center" align="center" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" :help-id="helpId"></breadcrumbs-with-help>
        </v-row>

        <v-row v-if="form != null">
          <v-col cols="12" md="7">
            <step1-type v-if="steps[1].active" :form="form" :steps="steps" :step="1" ref="validateForm"
              @form="form = $event" @valid="steps[1].valid = $event"></step1-type>

            <!-- Step 2 Who -->
            <step-2-who v-if="steps[2].active" :form="form" ref="validateForm" @form="form = $event"
              @valid="steps[2].valid = $event"></step-2-who>

            <!-- Step 4 Agreement type -->
            <!-- Rent -->
            <step-4-agreement-rent v-if="steps[3].active && form.type == 'Huur'" :form="form" ref="validateForm"
              @form="form = $event" @valid="steps[3].valid = $event"></step-4-agreement-rent>

            <!-- Partage -->
            <step-4-agreement-partage v-else-if="steps[3].active && form.type == 'Partage'" :form="form"
              ref="validateForm" @form="form = $event" @valid="steps[3].valid = $event"></step-4-agreement-partage>

            <!-- Tranche -->
            <step-4-agreement-tranche v-else-if="steps[3].active && form.type == 'Staffel'" :form="form"
              ref="validateForm" @form="form = $event" @valid="steps[3].valid = $event"></step-4-agreement-tranche>

            <!-- Suppletion -->
            <step-4-agreement-suppletion v-else-if="steps[3].active && form.type == 'Suppletie'" :form="form"
              ref="validateForm" @form="form = $event" @valid="steps[3].valid = $event"></step-4-agreement-suppletion>

            <!-- Buyout -->
            <step-4-agreement-buyout v-else-if="steps[3].active && form.type == 'Uitkoop'" :form="form"
              ref="validateForm" @form="form = $event" @valid="steps[3].valid = $event"></step-4-agreement-buyout>

            <!-- Step 5 Agreement -->
            <step-5-agreement v-else-if="steps[4].active" :form="form" ref="validateForm" @form="form = $event"
              @valid="steps[4].valid = $event"></step-5-agreement>

            <!-- Step 6 Contract Details -->
            <step-6-contract-details v-else-if="steps[5].active" :contract="form"></step-6-contract-details>

            <v-alert v-if="formError != ''" text color="error">{{ formError }}</v-alert>

            <!-- Buttons -->
            <!-- Back -->
            <v-btn v-if="!steps[5].active && !steps[0].active && !steps[1].active" rounded large outlined elevation="0"
              @click="back" :loading="formLoading" color="primary"
              class="text-lowercase mb-2 mr-2 align-self-start"><v-icon small left>mdi-arrow-left</v-icon>
              Ga een stap terug</v-btn>

            <!-- Next -->
            <v-btn v-if="steps[4].active" rounded large elevation="0" @click="next" :loading="formLoading"
              color="secondary" class="text-lowercase mb-2 mr-2 align-self-start">Bekijk afspraak
              <v-icon small right>mdi-arrow-right</v-icon></v-btn>
            <v-btn v-else-if="!steps[4].done && form.type != ''" rounded large elevation="0" @click="next"
              :loading="formLoading" color="secondary" class="text-lowercase mb-2 mr-2 align-self-start">Ga verder
              <v-icon small right>mdi-arrow-right</v-icon></v-btn>

            <!-- Save -->
            <v-btn v-if="steps[5].active" rounded large elevation="0" @click="submitProposed" :loading="formLoading"
              color="secondary" class="text-lowercase mb-2 mr-2 align-self-start">Afspraak voorstellen
              <v-icon small right>mdi-arrow-right</v-icon></v-btn>

            <!-- Edit -->
            <v-btn v-if="steps[5].active" rounded large outlined elevation="0" @click="edit" :loading="formLoading"
              color="primary" class="text-lowercase mb-2 mr-2 align-self-start">Afspraak bewerken
              <v-icon small right>mdi-file-edit-outline</v-icon></v-btn>

            <!-- Concept save -->
            <v-btn v-if="steps[2].done" rounded large outlined elevation="0" @click="submitConcept"
              :loading="formLoading" color="primary" class="text-lowercase mb-2 mr-2 align-self-start">Opslaan als
              concept
              <v-icon small right>mdi-content-save</v-icon></v-btn>
          </v-col>

          <v-col cols="12" md="5">
            <!-- Log -->
            <log :contract="form"></log>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" top color="secondary">{{ snackbarText }}</v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import router from '../../router/router';
import functions from '../../services/functions.service';

import Step2Who from './components/formSteps/Step2-Who-Edit';

import Step4AgreementRent from './components/formSteps/Step4-Agreement-Rent';
import Step4AgreementPartage from './components/formSteps/Step4-Agreement-Partage';
import Step4AgreementTranche from './components/formSteps/Step4-Agreement-Tranche';
import Step4AgreementSuppletion from './components/formSteps/Step4-Agreement-Suppletion';
import Step4AgreementBuyout from './components/formSteps/Step4-Agreement-Buyout';

import Step5Agreement from './components/formSteps/Step5-Agreement';

import Step6ContractDetails from './components/formSteps/Step6-ContractDetails';

import Log from './components/details/Log.vue';
import Step1Type from '@/views/Contracts/components/formSteps/Step1-Type';

export default {
  name: 'ContractsEdit',
  props: ['contractId', 'type'],
  components: {
    Step1Type,
    BreadcrumbsWithHelp,
    Log,

    Step2Who,

    Step4AgreementRent,
    Step4AgreementPartage,
    Step4AgreementTranche,
    Step4AgreementSuppletion,
    Step4AgreementBuyout,

    Step5Agreement,

    Step6ContractDetails,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'financiële afspraken',
          disabled: false,
          href: '/financiele-afspraken',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      steps: [
        {
          name: 'Step1',
          active: false,
          done: true,
          valid: true,
        },
        {
          name: 'Step2',
          active: false,
          done: true,
          valid: true,
        },
        {
          name: 'Step3 - Who',
          active: false,
          done: true,
          valid: true,
        },
        {
          name: 'Step4 - Agreement per type',
          active: true,
          done: false,
          valid: false,
        },
        {
          name: 'Step5 - Agreement',
          active: false,
          done: false,
          valid: false,
        },
        {
          name: 'Step6 - Agreement details',
          active: false,
          done: false,
          valid: false,
        },
      ],
      concept: null,
      snackbar: false,
      snackbarText: '',
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      form: null,
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('ContractsEdit');
    this.checkRights();
    this.getContract();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getContract() {
      const vm = this;
      var apiLinkGet = '';

      if (this.type == 'huur') {
        apiLinkGet = '/RentContracts/Get';
      } else if (this.type == 'partage') {
        apiLinkGet = '/PartageContracts/Get';
      } else if (this.type == 'staffel') {
        apiLinkGet = '/TrancheContracts/Get';
      } else if (this.type == 'suppletie') {
        apiLinkGet = '/SuppletionContracts/Get';
      } else if (this.type == 'uitkoop') {
        apiLinkGet = '/BuyoutContracts/Get';
      }

      ApiService.get(apiLinkGet, {
        ContractId: this.contractId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            vm.form = response.data.result;

            if (vm.form.type.toLowerCase() !== vm.type.toLowerCase() || !vm.form.canEdit || !vm.form.isLatestVersion) {
              router.back();
            }

            var performancesString = '(';
            for (var i = 0; i < vm.form.performances.length; i++) {
              if (i == 0) {
                performancesString += vm.form.performances[i].id;
              } else {
                performancesString += ' - ' + vm.form.performances[i].id;
              }
            }
            performancesString += ')';
            vm.breadcrumbs[1].text = vm.form.production.title + ' ' + performancesString;

            vm.form.theaterFee = functions.getEuroCurrencyView(vm.form.theaterFee);
            vm.form.consumptionSurcharge = functions.getEuroCurrencyView(vm.form.consumptionSurcharge);
            vm.form.serviceSurcharge = functions.getEuroCurrencyView(vm.form.serviceSurcharge);
            vm.form.wardrobeSurcharge = functions.getEuroCurrencyView(vm.form.wardrobeSurcharge);
            vm.form.ticketPrice1 = functions.getEuroCurrencyView(vm.form.ticketPrice1);
            vm.form.ticketPrice2 = functions.getEuroCurrencyView(vm.form.ticketPrice2);
            vm.form.ticketPrice3 = functions.getEuroCurrencyView(vm.form.ticketPrice3);
            vm.form.ticketPrice4 = functions.getEuroCurrencyView(vm.form.ticketPrice4);
            vm.form.ticketPrice5 = functions.getEuroCurrencyView(vm.form.ticketPrice5);
            vm.form.ticketPrice6 = functions.getEuroCurrencyView(vm.form.ticketPrice6);
            vm.form.ticketPrice7 = functions.getEuroCurrencyView(vm.form.ticketPrice7);
            vm.form.ticketPrice8 = functions.getEuroCurrencyView(vm.form.ticketPrice8);
            vm.form.ticketPrice9 = functions.getEuroCurrencyView(vm.form.ticketPrice9);
            vm.form.ticketPrice10 = functions.getEuroCurrencyView(vm.form.ticketPrice10);

            if (vm.type == 'huur') {
              vm.form.rent = functions.getEuroCurrencyView(vm.form.rent);
              vm.form.otherCosts = functions.getEuroCurrencyView(vm.form.otherCosts);
            } else if (vm.type == 'partage') {
              vm.form.copyrightPercentage = vm.form.copyrightPercentage.toString();
              vm.form.partageProviderPercentage = vm.form.partageProviderPercentage.toString();
              vm.form.providerGuarantee = functions.getEuroCurrencyView(vm.form.providerGuarantee);
              vm.form.consumerGuarantee = functions.getEuroCurrencyView(vm.form.consumerGuarantee);
            } else if (vm.type == 'staffel') {
              vm.form.copyrightPercentage = vm.form.copyrightPercentage.toString();
              vm.form.providerGuarantee = functions.getEuroCurrencyView(vm.form.providerGuarantee);
              vm.form.consumerGuarantee = functions.getEuroCurrencyView(vm.form.consumerGuarantee);
              vm.form.partageProviderAmount1 = functions.getEuroCurrencyView(vm.form.partageProviderAmount1);
              vm.form.partageProviderPercentage1 = vm.form.partageProviderPercentage1.toString();
              vm.form.partageProviderAmount2 = functions.getEuroCurrencyView(vm.form.partageProviderAmount2);
              vm.form.partageProviderPercentage2 = vm.form.partageProviderPercentage2.toString();
              vm.form.partageProviderAmount3 = functions.getEuroCurrencyView(vm.form.partageProviderAmount3);
              vm.form.partageProviderPercentage3 = vm.form.partageProviderPercentage3.toString();
              vm.form.partageProviderAmount4 = functions.getEuroCurrencyView(vm.form.partageProviderAmount4);
              vm.form.partageProviderPercentage4 = vm.form.partageProviderPercentage4.toString();
              vm.form.combinedBudget = functions.getEuroCurrencyView(vm.form.combinedBudget);
              vm.form.partageProviderPercentageRest = vm.form.partageProviderPercentageRest.toString();
            } else if (vm.type == 'suppletie') {
              vm.form.copyrightPercentage = vm.form.copyrightPercentage.toString();
              vm.form.partageProviderPercentage = vm.form.partageProviderPercentage.toString();
              vm.form.amount = functions.getEuroCurrencyView(vm.form.amount);
              vm.form.maximumAmount = functions.getEuroCurrencyView(vm.form.maximumAmount);
            } else if (vm.type == 'uitkoop') {
              vm.form.copyrightPercentage = vm.form.copyrightPercentage.toString();
              vm.form.buyoutAmount = functions.getEuroCurrencyView(vm.form.buyoutAmount);
            }

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          router.back();
        });
    },
    back() {
      var activeStep = this.getActiveStepId();

      if (activeStep == 0) {
        router.back();
      } else {
        this.steps[activeStep].active = false;
        this.steps[activeStep].done = false;
        this.steps[activeStep - 1].done = false;
        this.steps[activeStep - 1].active = true;
      }
    },
    next() {
      var activeStep = this.getActiveStepId();

      var activeForm = this.$refs.validateForm;
      activeForm.validateForm();

      if (this.steps[activeStep].valid) {
        this.steps[activeStep].active = false;
        this.steps[activeStep].done = true;
        this.steps[activeStep + 1].active = true;
      }
    },
    getActiveStepId() {
      var id = 0;
      for (var i = 0; i < this.steps.length; i++) {
        if (this.steps[i].active) {
          id = i;
        }
      }
      return id;
    },
    edit() {
      for (var i = 2; i < this.steps.length; i++) {
        this.steps[i].active = false;
        this.steps[i].done = false;
      }
      this.steps[2].active = true;
    },
    submit() {
      this.formError = '';
      this.formLoading = true;

      const form = this.form;

      var state;
      if (this.concept) {
        state = 'concept';
      } else if (form.isApprovedBefore) {
        state = 'revised';
      } else {
        state = 'proposed';
      }

      const putForm = {
        id: this.contractId,
        state: state,
        remark: form.remark,
        acceptTerms: form.acceptTerms,
        consumptionSurcharge: functions.getEuroCurrencyDatabase(form.consumptionSurcharge),
        serviceSurcharge: functions.getEuroCurrencyDatabase(form.serviceSurcharge),
        wardrobeSurcharge: functions.getEuroCurrencyDatabase(form.wardrobeSurcharge),
        theaterTechnicians: form.theaterTechnicians,
        producerTechnicians: form.producerTechnicians,
        ticketPrice1: functions.getEuroCurrencyDatabase(form.ticketPrice1),
        ticketPrice2: functions.getEuroCurrencyDatabase(form.ticketPrice2),
        ticketPrice3: functions.getEuroCurrencyDatabase(form.ticketPrice3),
        ticketPrice4: functions.getEuroCurrencyDatabase(form.ticketPrice4),
        ticketPrice5: functions.getEuroCurrencyDatabase(form.ticketPrice5),
        ticketPrice6: functions.getEuroCurrencyDatabase(form.ticketPrice6),
        ticketPrice7: functions.getEuroCurrencyDatabase(form.ticketPrice7),
        ticketPrice8: functions.getEuroCurrencyDatabase(form.ticketPrice8),
        ticketPrice9: functions.getEuroCurrencyDatabase(form.ticketPrice9),
        ticketPrice10: functions.getEuroCurrencyDatabase(form.ticketPrice10),
        theaterContactId: form.theaterContact?.id ?? 0,
        theaterContactEmail: form.theaterContact?.email,
        agencyAsProvider: form.agencyAsProvider,
        theaterFee: functions.getEuroCurrencyDatabase(form.theaterFee),
      };

      var apiLinkPut = '';

      if (form.type == 'Huur') {
        apiLinkPut = '/RentContracts/Update';

        putForm.rent = functions.getEuroCurrencyDatabase(form.rent);
        putForm.otherCosts = functions.getEuroCurrencyDatabase(form.otherCosts);
      } else if (form.type == 'Partage') {
        apiLinkPut = '/PartageContracts/Update';

        putForm.theaterFee = functions.getEuroCurrencyDatabase(form.theaterFee);
        putForm.copyrightPercentage = functions.getDecimalDatabase(form.copyrightPercentage);
        putForm.calculateCopyrightPercentage = form.calculateCopyrightPercentage;
        putForm.providerGuarantee = functions.getEuroCurrencyDatabase(form.providerGuarantee);
        putForm.consumerGuarantee = functions.getEuroCurrencyDatabase(form.consumerGuarantee);
        putForm.partageProviderPercentage = functions.getDecimalDatabase(form.partageProviderPercentage);
        putForm.guaranteePerPerformance = form.guaranteePerPerformance;
      } else if (form.type == 'Staffel') {
        apiLinkPut = '/TrancheContracts/Update';

        putForm.copyrightPercentage = functions.getDecimalDatabase(form.copyrightPercentage);
        putForm.calculateCopyrightPercentage = form.calculateCopyrightPercentage;
        putForm.providerGuarantee = functions.getEuroCurrencyDatabase(form.providerGuarantee);
        putForm.consumerGuarantee = functions.getEuroCurrencyDatabase(form.consumerGuarantee);

        putForm.partageProviderAmount1 = functions.getEuroCurrencyDatabase(form.partageProviderAmount1);
        putForm.partageProviderPercentage1 = functions.getDecimalDatabase(form.partageProviderPercentage1);
        putForm.partageProviderAmount2 = functions.getEuroCurrencyDatabase(form.partageProviderAmount2);
        putForm.partageProviderPercentage2 = functions.getDecimalDatabase(form.partageProviderPercentage2);
        putForm.partageProviderAmount3 = functions.getEuroCurrencyDatabase(form.partageProviderAmount3);
        putForm.partageProviderPercentage3 = functions.getDecimalDatabase(form.partageProviderPercentage3);
        putForm.partageProviderAmount4 = functions.getEuroCurrencyDatabase(form.partageProviderAmount4);
        putForm.partageProviderPercentage4 = functions.getDecimalDatabase(form.partageProviderPercentage4);
        putForm.partageProviderPercentageRest = functions.getDecimalDatabase(form.partageProviderPercentageRest);
        putForm.combinedBudget = functions.getEuroCurrencyDatabase(form.combinedBudget);
      } else if (form.type == 'Suppletie') {
        apiLinkPut = '/SuppletionContracts/Update';

        putForm.copyrightPercentage = functions.getDecimalDatabase(form.copyrightPercentage);
        putForm.calculateCopyrightPercentage = form.calculateCopyrightPercentage;
        putForm.partageProviderPercentage = functions.getDecimalDatabase(form.partageProviderPercentage);
        putForm.amount = functions.getEuroCurrencyDatabase(form.amount);
        putForm.maximumAmount = functions.getEuroCurrencyDatabase(form.maximumAmount);
        putForm.guaranteePerPerformance = form.guaranteePerPerformance;
      } else if (form.type == 'Uitkoop') {
        apiLinkPut = '/BuyoutContracts/Update';

        putForm.copyrightPercentage = functions.getDecimalDatabase(form.copyrightPercentage);
        putForm.buyoutAmount = functions.getEuroCurrencyDatabase(form.buyoutAmount);
      }

      ApiService.put(apiLinkPut, putForm)
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          if (response.data.success) {
            this.isEditing = false;
            if (state == 'proposed') {
              this.snackbarText = 'financiële afspraak voorgesteld';
            } else if (state == 'revised') {
              this.snackbarText = 'financiële afspraak herzien';
            } else {
              this.snackbarText = 'financiële afspraak opgeslagen';
            }
            this.snackbar = true;
            setTimeout(function () {
              router.push({ name: 'Contracts' });
            }, 1000);
          } else {
            this.formLoading = false;
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
          }
        })
        // eslint-disable-next-line
        .catch(() => {
          this.formLoading = false;
          this.formError =
            'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
        });
    },
    submitProposed() {
      this.concept = false;
      this.submit();
    },
    submitConcept() {
      this.concept = true;
      this.submit();
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    helpId() {
      if (this.form != null && this.steps[2].active) {
        return "1104";
      } else if (this.form != null && this.steps[3].active && this.form.type == 'Huur') {
        return "1097";
      } else if (this.form != null && this.steps[3].active && this.form.type == 'Partage') {
        return "1098";
      } else if (this.form != null && this.steps[3].active && this.form.type == 'Staffel') {
        return "1099";
      } else if (this.form != null && this.steps[3].active && this.form.type == 'Suppletie') {
        return "1100";
      } else if (this.form != null && this.steps[3].active && this.form.type == 'Uitkoop') {
        return "1101";
      } else if (this.form != null && this.steps[4].active) {
        return "1102";
      } else if (this.form != null && this.steps[5].active) {
        return "1103";
      } else {
        return null;
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style scoped></style>
