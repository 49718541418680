<template>
  <v-menu
    :close-on-click="true"
    :close-on-content-click="false"
    v-model="datePickerDialog"
    max-width="290px"
    min-width="auto"
    nudge-top="-40"
  >
    <template v-slot:activator="{ props }">
      <v-text-field
        v-model="formattedDate"
        v-bind="props"
        :label="datePickerLabel ?? 'startdatum'"
        placeholder="DD-MM-JJJJ"
        @update:model-value="dateTyped"
        @click="openDatePicker"
        :rules="datePickerDateRequired ? formValidationRules.dateRequired : formValidationRules.date"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="datePickerStart"
      no-title
      hide-header
      first-day-of-week="1"
      @update:model-value="datePicked"
      color="success"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../services/functions.service';

export default {
  props: ['passedDate', 'rules', 'label'],
  data() {
    return {
      formattedDate: this.passedDate && this.passedDate !== '' ? this.passedDate : '',
      datePickerDialog: false,
      formValidationRules: {},
      datePickerLabel: this.label,
      datePickerDateRequired: false,
      datePickerStart: this.passedDate && this.passedDate !== '' 
        ? this.normalizeDate(this.passedDate) 
        : null,
    };
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();

    if (this.rules === 'date' || this.rules === undefined) {
      this.datePickerDateRequired = false;
    } else {
      this.datePickerDateRequired = true;
    }
  },
  methods: {
    normalizeDate(dateString) {
      if (!dateString) return null;

      const date = new Date(dateString);
      date.setHours(0, 0, 0, 0);
      return date;
    },
    openDatePicker() {
      this.datePickerDialog = true;
    },
    datePicked(date) {
      if (!date) return;

      this.formattedDate = this.formatDateForInput(date);
      this.datePickerDialog = false;

      this.$emit('formatedDate', this.formattedDate);
    },
    formatDateForInput(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    dateTyped(date) {
      if (!date || date.length !== 10) return;
      this.datePickerStart = this.normalizeDate(date);
      this.$emit('formatedDate', this.formattedDate);
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>

<style>
.v-date-picker-table--date td {
  padding: 5px 0;
}
</style>