<template>
  <span>
    <v-btn v-if="user.isSuperUser" :color="bgColor" class="ml-2" rounded="circle" elevation="0" size="x-small"
      icon="mdi-cog" @click="dialogEdit = true">
      <v-icon color="primary">mdi-cog</v-icon>
    </v-btn>
    <v-btn :color="bgColor" class="ml-2" rounded="circle" elevation="0" density="default" size="x-small" icon="mdi-help"
      @click="dialogHelp = true">
      <v-icon color="primary">mdi-help</v-icon>
    </v-btn>

    <v-dialog v-model="dialogHelp" max-width="700px">
      <v-card>
        <v-card-title class="text-primary d-flex align-center">
          {{ originalTitle }}
          <v-spacer></v-spacer>
          <v-btn color="#fff" rounded="circle" elevation="0" size="small" icon="mdi-close" @click="dialogHelp = false">
            <v-icon color="primary" x-small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-primary caption pre-wrap">
          <span v-html="urlInText(originalText)"></span>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="user.isSuperUser" v-model="dialogEdit" max-width="700px">
      <v-card>
        <v-card-title class="text-primary d-flex align-center">
          Bewerken helpfunctie
          <v-spacer></v-spacer>
          <v-btn color="#fff" rounded="circle" elevation="0" size="small" icon="mdi-close" @click="
            helpText = originalText;
          dialogEdit = false;
          ">
            <v-icon color="primary" x-small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent ref="form" v-model="formValid" class="pt-5">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field class="text-primary" label="titel" v-model="editTitle"
                  :rules="formValidationRules.required" required outlined dense></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="py-0">
                <v-textarea class="text-primary" label="tekst" v-model="editText" :rules="formValidationRules.required"
                  required outlined></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="py-0">
                <v-btn-save class="mx-2" @click="submit" :loading="formLoading">
                  opslaan
                </v-btn-save>
                <v-btn-cancel class="mx-2" @click="
                  editText = originalText;
                editTitle = originalTitle;
                dialogEdit = false;
                ">
                  Annuleren
                </v-btn-cancel>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import ApiService from '../services/api.service';
import { mapState } from 'vuex';
import functions from '../services/functions.service';

export default {
  props: ['helpId', 'bgColor'],
  data() {
    return {
      originalText: '',
      originalTitle: '',
      editText: '',
      editTitle: '',
      dialogHelp: false,
      dialogEdit: false,
      formLoading: false,
      formValid: false,
      formValidationRules: {},
    };
  },
  mounted() {
    this.getHelp();
    this.formValidationRules = functions.getFormValidationRules();
  },
  methods: {
    urlInText(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
      });
    },
    getHelp: function () {
      if (this.helpId === null) {
        return;
      }
      ApiService.get('/HelpFunctions/Get', {
        id: this.helpId,
      })
        .then((response) => {
          var help = response.data.result;

          this.originalTitle = help.title;
          this.editTitle = help.title;

          this.originalText = help.text;
          this.editText = help.text;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    submit() {
      this.formLoading = true;
      if (this.formValid) {
        ApiService.put('/HelpFunctions/Update', {
          id: this.helpId,
          title: this.editTitle,
          text: this.editText,
        })
          .then((response) => {
            if (response.data.success) {
              this.originalText = this.editText;
              this.originalTitle = this.editTitle;
              this.formLoading = false;
              this.dialogEdit = false;
            } else {
              this.formLoading = false;
            }
          })
          .catch((error) => {
            this.formError = error;
            // eslint-disable-next-line
            console.log('error ' + error);
            this.errored = true;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    helpId: {
      handler() {
        this.getHelp();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.pre-wrap {
  white-space: pre-wrap;
}
</style>
