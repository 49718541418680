<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="29"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row class="d-flex" justify="center" align="center" no-gutters>
            <v-col cols="12">
              <v-card title="gegevens kenmerk">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <p class="text-primary mb-0">
                        <small>naam:</small> <br />
                        <strong>{{ characteristic.name }}</strong>
                      </p>
                      <hr class="accent-hr my-2" />
                      <p class="text-primary mb-0">
                        <small>code:</small> <br />
                        <strong>{{ characteristic.code }}</strong>
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <div>
                    <p class="grey--text text--lighten-1 mb-2">
                      <small><i>{{ this.characteristic.lastEditedBy }}</i></small>
                    </p>

                    <!-- Bewerken -->
                    <v-btn-edit class="mr-5" :to="{ name: 'AdminCharacteristicsEdit' }">Gegevens Bewerken</v-btn-edit>

                    <!-- Verwijderen -->
                    <v-btn-delete @click="deleteItemDialog = true">Verwijderen</v-btn-delete>
                  </div>
                </v-card-actions>
              </v-card>

            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Delete dialog starts here -->
      <v-dialog v-model="deleteItemDialog" persistent width="500">
        <v-card class="pa-6" flat>
          <v-card-title class="headline">{{ this.characteristic.name }} verwijderen?</v-card-title>
          <v-card-text>Weet je zeker dat je {{ this.characteristic.name }} wil verwijderen? Het is daarna niet meer
            terug
            te
            vinden.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="deleteItemDialog = false">Nee</v-btn>
            <v-btn color="primary" text @click="deleteItemCharacteristic()">Ja</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ this.snackbarText }}
        <template v-slot:actions>
          <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
            Sluiten
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminCharacteristicsDetail',
  props: ['characteristicId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      characteristic: {
        name: '',
        code: '',
        lastEditedBy: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'kenmerken',
          disabled: false,
          href: '/admin/kenmerken',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemDialog: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
    };
  },
  mounted() {
    this.getCharacteristic();
  },
  methods: {
    getCharacteristic: function () {
      ApiService.get('/Characteristics/Get', {
        characteristicId: this.characteristicId,
      })
        .then((response) => {
          if (response.data.success) {
            const characteristic = response.data.result;
            this.breadcrumbs[2].text = characteristic.name;
            this.characteristic.name = characteristic.name;
            this.characteristic.code = characteristic.code;
            this.characteristic.lastEditedBy = characteristic.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    deleteItemCharacteristic: function () {
      ApiService.delete('/Characteristics/Delete', {
        characteristicId: this.characteristicId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.characteristic.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.snackbarColor = 'secondary';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/admin/kenmerken' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {},
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
