<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1134"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card title="gegevens externe partij">
            <v-divider></v-divider>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>naam:</small> <br />
                    <strong>{{ thirdPartyInfo.name }}</strong>
                  </p>
                </v-col>
              </v-row>

              <hr class="accent-hr my-2" />

              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>client id:</small> <br />
                    <strong>{{ thirdPartyInfo.clientId }}</strong>
                  </p>
                </v-col>
              </v-row>

              <hr class="accent-hr my-2" />

              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>secret:</small> <br />
                    <strong>{{ thirdPartyInfo.clientSecret }}</strong>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card title="api rechten">
            <v-divider></v-divider>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>data overdracht FPK:</small> <br />
                    <strong><span v-if="thirdPartyInfo.hasDataApi">Ja</span><span v-else>Nee</span></strong>
                  </p>
                </v-col>
              </v-row>
              <hr class="accent-hr my-2" />
              <v-row no-gutters>
                <v-col cols="12" class="py-0">
                  <p class="text-primary mb-0">
                    <small>productiegegevens FPK:</small> <br />
                    <strong><span v-if="thirdPartyInfo.hasProducerApi">Ja</span><span v-else>Nee</span></strong>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <div>
                <p class="grey--text text--lighten-1 mb-2">
                  <small><i>{{ this.thirdPartyInfo.lastEditedBy }}</i></small>
                </p>

                <!-- Bewerken -->
                <v-btn-edit class="mr-5" :to="{ name: 'AdminThirdPartiesEdit' }">gegevens Bewerken</v-btn-edit>

                <!-- Refresh token -->
                <v-btn size="large" color="primary" rounded depressed class="text-lowercase align-self-start mr-5"
                  append-icon="mdi-refresh" @click="refreshClientCredentialsDialog = true">
                  refresh credentials
                </v-btn>

                <!-- Verwijderen -->
                <v-btn-delete @click="deleteItemDialog = true">Verwijderen</v-btn-delete>
              </div>
            </v-card-actions>
          </v-card>
          <v-col cols="12">

          </v-col>
        </v-col>
      </v-row>

      <!-- Delete dialog third partij starts here -->
      <v-dialog v-model="deleteItemDialog" persistent width="500">
        <v-card class="pa-6" flat>
          <v-card-title class="headline">{{ this.thirdPartyInfo.firstName }} verwijderen?</v-card-title>
          <v-card-text>Weet je zeker dat je {{ this.thirdPartyInfo.firstName }} wil verwijderen? Het is daarna niet meer
            terug te
            vinden.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="deleteItemDialog = false">Nee</v-btn>
            <v-btn color="primary" text @click="deleteThirdParty()">Ja</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete dialog token starts here -->
      <v-dialog v-model="refreshClientCredentialsDialog" persistent width="500">
        <v-card class="pa-6" flat>
          <v-card-title class="headline">Client credentials refeshen</v-card-title>
          <v-card-text>Weet je zeker dat je dit client credentials wil refreshen? Het is daarna niet meer terug te
            draaien.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="refreshClientCredentialsDialog = false">Nee</v-btn>
            <v-btn color="primary" text @click="refreshClientCredentials()">Ja</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ this.snackbarText }}
        <template v-slot:actions>
          <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
            Sluiten
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminThirdPartiesDetail',
  props: ['thirdPartyId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      thirdPartyInfo: {
        name: '',
        hasDataApi: false,
        hasProducerApi: false,
        lastEditedBy: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'externe partijen',
          disabled: false,
          href: '/admin/externe-partijen',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemDialog: false,
      refreshClientCredentialsDialog: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
    };
  },
  mounted() {
    this.getThirdParty();
  },
  methods: {
    getThirdParty: function () {
      ApiService.get('/ThirdPartyUsers/Get', {
        id: this.thirdPartyId,
      })
        .then((response) => {
          if (response.data.success) {
            const thirdPartyInfo = response.data.result;

            this.thirdPartyInfo = thirdPartyInfo;

            this.breadcrumbs[2].text = this.thirdPartyInfo.name;
            this.breadcrumbs[2].href = '/admin/externe-partijen/' + thirdPartyInfo.id;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    refreshClientCredentials: function () {
      this.tableThirdPartiesTokensLoading = true;
      ApiService.put('/ThirdPartyUsers/RefreshClientCredentials', {
        id: this.thirdPartyId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.thirdPartyInfo.name + ' heeft nieuwe client credentials';
            this.refreshClientCredentialsDialog = false;
            this.snackbarColor = 'secondary';
            this.snackbar = true;
            this.getThirdParty();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.tableThirdPartiesTokensLoading = false;
        });
    },
    deleteThirdParty: function () {
      ApiService.delete('/ThirdPartyUsers/Delete', {
        id: this.thirdPartyId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.thirdPartyInfo.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.snackbarColor = 'secondary';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/admin/externe-partijen' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
