<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row align="center" class="px-3 mb-4">
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Een nieuw kenmerk toevoegen?</span>
            <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase"
              :to="{ name: 'AdminCharacteristicsAdd' }">
              Aanmaken nieuw kenmerk
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="27"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field v-model="tableCharacteristicsFilter.name" label="op naam zoeken" hide-details
                variant="outlined" density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tableCharacteristicsFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field v-model="tableCharacteristicsFilter.name" label="op naam zoeken" hide-details dense
                      outlined background-color="#fff" class="mb-3"></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableCharacteristicsFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" variant="outlined" dark
                      @click="clearFilterCharacteristics()">reset filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tableCharacteristicsHeaders" :items="tableCharacteristicsFiltered"
          :mobile-breakpoint="0" :loading="tableCharacteristicsLoading" :page="tableCharacteristicsPaging.page"
          :items-per-page="tableCharacteristicsPaging.itemsPerPage" hide-default-footer
          @update:current-items="tableCharacteristicsPaging.pageCount = Math.ceil(tableCharacteristicsFiltered.length / tableCharacteristicsPaging.itemsPerPage)"
          @update:items-per-page="tableCharacteristicsPaging.pageCount = Math.ceil(tableCharacteristicsFiltered.length / tableCharacteristicsPaging.itemsPerPage)"
          @click:row="(_, x) => goToDetail(x.item)" class="table-action row-is-clickable" fixed-header
          :height="tableCharacteristicsFiltered.length > 10 ? '55vh' : ''">
          <template v-slot:[`item.action`]="{ item }">
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-eye" color="warning" class="ml-1"
              @click.stop="goToDetail(item)">
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline" color="info" class="ml-1"
              @click.stop="goToEdit(item)">
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-delete" color="error" class="ml-1"
              @click.stop="(deleteItem = item), (deleteItemDialog = true);">
              <v-icon color="#fff">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <table-settings :items="tableCharacteristicsFiltered" :paging="tableCharacteristicsPaging"
          :export-columns="downloadExportColumns" :file-name="'dip-kenmerken-export'" :sheet-name="'Kenmerken'"
          :show-excel-button="true"></table-settings>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog v-model="deleteItemDialog" persistent width="500">
      <v-card class="pa-6" flat>
        <v-card-title class="headline">{{ this.deleteItem.name }} verwijderen?</v-card-title>
        <v-card-text>Weet je zeker dat je {{ this.deleteItem.name }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="(deleteItemDialog = false), (deleteItem = '');">Nee</v-btn>
          <v-btn color="primary" text @click="deleteItemCharacteristic()">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ this.snackbarText }}
      <template v-slot:actions>
        <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
          Sluiten
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';

export default {
  name: 'AdminCharacteristics',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'kenmerken',
          disabled: false,
          href: '/admin/kenmerken',
          bold: true,
        },
      ],
      characteristics: [],
      tableCharacteristicsFiltered: [],
      tableCharacteristicsLoading: true,
      tableCharacteristicsHeaders: [
        { title: 'naam', key: 'name' },
        { title: 'code', key: 'code' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableCharacteristicsFilter: {
        name: '',
      },
      tableCharacteristicsPaging: {
        page: 1,
        pageCount: 25,
        itemsPerPage: 25,
      },
      tableCharacteristicsFilterDialog: false,
      downloadExportColumns: [
        { label: 'ID', field: 'id' },
        { label: 'naam', field: 'name' },
        { label: 'code', field: 'code' },
      ],
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'secondary',
    };
  },
  mounted() {
    // Filter
    this.checkFilter({ viewName: this.$options.name, filterObject: this.tableCharacteristicsFilter });
    this.tableCharacteristicsFilter = this.getFilter(this.$options.name);

    this.getCharacteristics();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'AdminCharacteristicsDetail',
        params: { characteristicId: row.id },
      });
    },
    goToEdit(row) {
      this.$router.push({
        name: 'AdminCharacteristicsEdit',
        params: { characteristicId: row.id },
      });
    },
    getCharacteristics: function () {
      ApiService.get('/Characteristics/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.characteristics = response.data.result;
          this.tableCharacteristicsFiltered = this.characteristics;

          // Paging
          this.checkFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableCharacteristicsPaging });
          this.tableCharacteristicsPaging = this.getFilter(this.$options.name + '-paging');

          this.filterCharacteristics();

          this.tableCharacteristicsLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterCharacteristics: function () {
      const vm = this;
      this.tableCharacteristicsFiltered = this.characteristics.filter(function (characteristic) {
        return cleanWords(characteristic.name).indexOf(cleanWords(vm.tableCharacteristicsFilter.name)) !== -1;
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterCharacteristics: function () {
      this.tableCharacteristicsFilter.name = '';
      this.tableCharacteristicsFilterDialog = false;
    },
    deleteItemCharacteristic: function () {
      ApiService.delete('/Characteristics/Delete', {
        characteristicId: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.characteristics.map((item) => item.id).indexOf(this.deleteItem.id);
            this.characteristics.splice(i, 1);
            this.filterCharacteristics();

            this.snackbarText = this.deleteItem.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'secondary';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('storage', ['getFilter']),
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({ viewName: this.$options.name, filterObject: this.tableCharacteristicsFilter });
    // Paging
    this.updateFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableCharacteristicsPaging });
  },
  watch: {
    tableCharacteristicsFilter: {
      handler: 'filterCharacteristics',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
